import React, { useState, useEffect } from 'react';

import { withRouter } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import { connect } from 'react-redux';

import * as ROUTES from '../../constants/routes';
import { Box } from '@material-ui/core';
import AdminNav from './nav';
import AdminDrawer from './admin_drawer';
import { DeviceContext } from '../web/device';

import UsersPage from './users';
import AdminDevices from './admin_devices';
import NotificationPage from './notifications';
import CustomerRequestPage from './customer_request';
import RetailCollection from './retailCollection';
import MapPage from './map';
// import ReportPage from "../web/component/report";
import AdminMonthlyReport from './report/monthlyReport';
import AdminDailyReport from './report/adminDailyReport';
import AdminDailyTravelReport from './report/adminDailyTravelReport';
import AdminDailySpeedReport from './report/adminDailySpeedReport';
import MonthlyTemperatureLog from '../web/component/monthly_temperature_log';
import MonthlyPTIOReport from '../web/component/monthly_ptio_report';

const pages = [
  (props) => <UsersPage {...props} />,
  (props) => <AdminDevices {...props} />,
  (props) => <NotificationPage {...props} />,
  (props) => <CustomerRequestPage {...props} />,
  (props) => <RetailCollection {...props} />,
  (props) => <MapPage {...props} />,
  (props) => <AdminMonthlyReport {...props} />,
  (props) => <AdminDailyReport {...props} />,
  (props) => <MonthlyTemperatureLog {...props} />,
  (props) => <MonthlyPTIOReport {...props} />,
  (props) => <AdminDailyTravelReport {...props} />,
  (props) => <AdminDailySpeedReport {...props} />,
];

const AdminPage = ({ authUser, history }) => {
  const [state, setState] = useState({
    drawerOpen: false,
    title: '',
    components: [1],
    device: null,
    user: null,
  });
  useEffect(() => {
    if (!authUser) {
      authUser = JSON.parse(localStorage.getItem('authUser'));
    }

    // console.log(authUser,"Auth User")

    if (!authUser.is_admin) {
      history.push(ROUTES.SIGN_IN);
    }
  }, []);

  const menuClick = () => {
    setState((old) => ({ ...old, drawerOpen: !old.drawerOpen }));
    // setDrawerOpen((old) => !old);
  };

  const setTitle = (title) => {
    setState((old) => ({ ...old, title: title }));
  };

  const setDrawerOpen = (open) => {
    setState((old) => ({ ...old, drawerOpen: open }));
  };

  const setDevice = (device) => {
    setState((old) => ({ ...old, device: device }));
  };

  const setUser = (user) => {
    setState((old) => ({ ...old, user: user }));
  };

  const backClick = () => {
    let jjj = [...state.components];
    jjj.pop();
    setState((old) => ({ ...old, components: jjj }));
  };

  const handleDrawerItemClick = (index) => {
    const jjj = [...state.components, index];
    setState((old) => ({ ...old, components: jjj }));
  };

  const handleMapClick = (device) => {
    const jjj = [...state.components, 5];
    setState((old) => ({ ...old, components: jjj, device: device }));
  };

  const reportClick = (device) => {
    const jjj = [...state.components, 6];
    setState((old) => ({ ...old, components: jjj, device: device }));
  };

  const dailyReportClick = (device) => {
    const jjj = [...state.components, 7];
    setState((old) => ({ ...old, components: jjj, device: device }));
  };

  const monthlyTemperatureLog = (device) => {
    const jjj = [...state.components, 8];
    setState((old) => ({ ...old, components: jjj, device: device }));
  };

  const monthlyPTIOReport = (device) => {
    const jjj = [...state.components, 9];
    setState((old) => ({ ...old, components: jjj, device: device }));
  };

  const dailyTravelReportClick = (device) => {
    const jjj = [...state.components, 10];
    setState((old) => ({ ...old, components: jjj, device: device }));
  };

  const dailySpeedReportClick = (device) => {
    const jjj = [...state.components, 11];
    setState((old) => ({ ...old, components: jjj, device: device }));
  };

  const renderPage = () => {
    const index = state.components[state.components.length - 1];
    const Page = pages[index];

    return <Page />;
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{ width: '100%', height: '100vh' }}
    >
      <AdminNav
        title={state.title}
        components={state.components}
        menuClick={menuClick}
        backClick={backClick}
      />
      <Box display="flex" flexGrow="1">
        <DeviceContext.Provider
          value={{
            device: state.device,
            setDevice: setDevice,
            user: authUser,
            setUser: setUser,
            title: state.title,
            setTitle: setTitle,
            handleMapClick: handleMapClick,
            reportClick: reportClick,
            dailyReportClick: dailyReportClick,
            monthlyTemperatureLog: monthlyTemperatureLog,
            monthlyPTIOReport: monthlyPTIOReport,
            dailyTravelReportClick: dailyTravelReportClick,
            dailySpeedReportClick: dailySpeedReportClick,
          }}
        >
          {authUser && (
            <Box display="flex" style={{ width: '100%', height: '100%' }}>
              {renderPage()}
            </Box>
          )}
        </DeviceContext.Provider>
      </Box>
      <AdminDrawer
        open={state.drawerOpen}
        setOpen={setDrawerOpen}
        authUser={authUser}
        handleDrawerItemClick={handleDrawerItemClick}
        components={state.components}
      />
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(withFirebase(withRouter(AdminPage)));
