import { utils, writeFileXLSX, CellStyle } from 'xlsx-js-style';

const getColumnWidthofRouteSummeryTable = (datas, headers) => {
  //id	registration_number	chasis_number	model	code	day_count	start_time	end_time	location_count	distance	running_time	congestion_time	idle_time

  let data = [...datas];
  let arr = [
    {
      wch: data.reduce(
        (w, r) => Math.max(w, r.start_address.length),
        headers[0].length
      ),
    },
    {
      wch: data.reduce(
        (w, r) => Math.max(w, r.start_time.length),
        headers[1].length
      ),
    },
    {
      wch: data.reduce(
        (w, r) => Math.max(w, r.distance.length),
        headers[2].length
      ),
    },
    {
      wch: data.reduce(
        (w, r) => Math.max(w, r.duration.length),
        headers[3].length
      ),
    },
    {
      wch: data.reduce(
        (w, r) => Math.max(w, r.end_time.length),
        headers[4].length
      ),
    },
    {
      wch: data.reduce(
        (w, r) => Math.max(w, r.end_address.length),
        headers[5].length
      ),
    },
  ];

  return arr;
};

export const downloadRouteSummery = (datas) => {
  const headers = [
    'Start Address',
    'Start At',
    'Distance(KM)',
    'Duration',
    'End At',
    'End Address',
  ];

  const ws = utils.json_to_sheet(datas, { origin: 'A2' });
  ws['!cols'] = getColumnWidthofRouteSummeryTable(datas, headers);

  utils.sheet_add_aoa(ws, [headers], { origin: 'A2' });

  //   ws['A3'].s = {
  //     font: {
  //       name: 'Calibri',
  //       sz: 50,
  //       bold: true,
  //       color: { rgb: 'FF0000' },
  //     },
  //   };

  const wb = utils.book_new();

  utils.book_append_sheet(wb, ws, 'Route Summery');
  writeFileXLSX(wb, 'Route Summery.xlsx');
};
