import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { updateDevice } from "../../actions";

import {
  Slide,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  TextField,
  Select,
  InputLabel,
  Box,
  CircularProgress,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import {
  REFERENCE_LIST,
  DEVICE_TYPE_LIST,
  VEHICLE_TYPE,
} from "../../utils/web_utils";
import { withFirebase } from "../Firebase";
import { withDevice } from "../web/device";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const initialErrorState = { error: false, message: "", fieldId: 0 };

const initialState = {
  id: "",
  registration_number: "",
  vehicle_model: "",
  vehicle_type: 0,
  service_charge: 0,
  device_sim_number: "",
  center_number: "",
  reference: "",
  device_model: "GT06",
  code: "",
  chasis_number: "",
  mask_id: "",
  over_due: false,
};

const UpdateDevice = ({
  open,
  setOpen,
  firebase,
  updateDevice,
  deviceParam
}) => {

  const { device } = deviceParam;


  const [state,setState]=useState({
    dev:{...initialState},
    err:{...initialErrorState},
    show:false
  })



  useEffect(() => {
    setState(old=>({...old,dev:{...device}}))
  }, [device]);

  const handleChange = (e) => {
    const cDev = { ...state.dev };
    cDev[e.target.name] = e.target.value;
    setState(old=>({...old,dev:cDev}))
  };


  const handleCheckChange=(event)=>{
    const cDev = { ...state.dev };
    cDev["over_due"] = event.target.checked;
    setState(old=>({...old,dev:cDev}))
  }

  const resolve = () => {
    setOpen(false);
    setState(old=>({...old,dev:{...initialState},show:false}))
  };

  const handleSubmit = (e) => {
    if (state.dev.id === "") {
      setState(old=>({...old,err:{
        error: true,
        message: "Empty Field is not Allowed",
        fieldId: 1,
      }}))
    } else if (state.dev.registration_number === "") {
      setState(old=>({...old,err:{
        error: true,
        message: "Empty Field is not Allowed",
        fieldId: 2,
      }}))
      
    } else if (state.dev.vehicle_model === "") {
      setState(old=>({...old,err:{
        error: true,
        message: "Empty Field is not Allowed",
        fieldId: 3,
      }}))
     
    } else if (state.dev.device_sim_number === "") {
      setState(old=>({...old,err:{
        error: true,
        message: "Empty Field is not Allowed",
        fieldId: 4,
      }}))
      
    } else if (state.dev.center_number === "") {
      setState(old=>({...old,err:{
        error: true,
        message: "Empty Field is not Allowed",
        fieldId: 5,
      }}))
     
    } else {
      setState(old=>({...old,err:{...initialErrorState},show:true}))
      updateDevice(state.dev, device, firebase, resolve);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title"
      TransitionComponent={Transition}
    >
      <DialogTitle
        id="form-dialog-title"
        style={{ textTransform: "uppercase" }}
      >
        Create a new Device
      </DialogTitle>

      {state.show && (
        <Box display="flex" justifyContent="center">
          <CircularProgress color="secondary" />
        </Box>
      )}

      <DialogContent>
        <TextField
          autoFocus
          error={state.err.error && state.err.fieldId === 1}
          label={state.err.error && state.err.fieldId === 1 ? state.err.message : "ID"}
          name="id"
          variant="outlined"
          onChange={handleChange}
          value={state.dev.id}
          fullWidth
          style={{ marginTop: 10 }}
          required
        />

        <TextField
          autoFocus
          error={state.err.error && state.err.fieldId === 2}
          label={
            state.err.error && state.err.fieldId === 2 ? state.err.message : "Registration Number"
          }
          name="registration_number"
          variant="outlined"
          onChange={handleChange}
          value={state.dev.registration_number}
          fullWidth
          style={{ marginTop: 10 }}
          required
        />

<TextField
          label="Chasis Number"
          name="chasis_number"
          variant="outlined"
          onChange={handleChange}
          value={state.dev.chasis_number}
          fullWidth
          style={{ marginTop: 10 }}
          
        />

        <TextField
          autoFocus
          error={state.err.error && state.err.fieldId === 3}
          label={state.err.error && state.err.fieldId === 3 ? state.err.message : "Model"}
          name="vehicle_model"
          variant="outlined"
          onChange={handleChange}
          value={state.dev.vehicle_model}
          fullWidth
          style={{ marginTop: 10 }}
          required
        />

        <TextField
          label="Code"
          name="code"
          variant="outlined"
          onChange={handleChange}
          value={state.dev.code}
          fullWidth
          style={{ marginTop: 10 }}
          
        />

<TextField
          label="Mask ID"
          name="mask_id"
          variant="outlined"
          onChange={handleChange}
          value={state.dev.mask_id}
          fullWidth
          style={{ marginTop: 10 }}
          
        />

        <TextField
          autoFocus
          error={state.err.error && state.err.fieldId === 4}
          label={
            state.err.error && state.err.fieldId === 4 ? state.err.message : "Device SIM Number"
          }
          name="device_sim_number"
          variant="outlined"
          onChange={handleChange}
          value={state.dev.device_sim_number}
          fullWidth
          style={{ marginTop: 10 }}
          required
        />

        <TextField
          autoFocus
          error={state.err.error && state.err.fieldId === 5}
          label={state.err.error && state.err.fieldId === 5 ? state.err.message : "Center Number"}
          name="center_number"
          variant="outlined"
          onChange={handleChange}
          value={state.dev.center_number}
          fullWidth
          style={{ marginTop: 10 }}
          required
        />

        <TextField
          autoFocus
          error={state.err.error && state.err.fieldId === 6}
          label={
            state.err.error && state.err.fieldId === 6 ? state.err.message : "Service Charge"
          }
          name="service_charge"
          variant="outlined"
          onChange={handleChange}
          value={state.dev.service_charge}
          fullWidth
          style={{ marginTop: 10 }}
          required
        />

        <InputLabel htmlFor="reference" style={{ marginTop: 10 }}>
          References
        </InputLabel>
        <Select
          style={{ marginTop: 10 }}
          native
          value={state.dev.reference}
          onChange={handleChange}
          inputProps={{
            name: "reference",
            id: "reference",
          }}
          fullWidth
        >
          {REFERENCE_LIST.map((ref, index) => (
            <option key={index} value={ref}>
              {ref}
            </option>
          ))}
        </Select>

        <InputLabel htmlFor="device_model" style={{ marginTop: 10 }}>
          Device Model
        </InputLabel>
        <Select
          style={{ marginTop: 10 }}
          native
          value={state.dev.device_model}
          onChange={handleChange}
          inputProps={{
            name: "device_model",
            id: "device_model",
          }}
          fullWidth
        >
          {DEVICE_TYPE_LIST.map((ref, index) => (
            <option key={index} value={ref}>
              {ref}
            </option>
          ))}
        </Select>

        <InputLabel htmlFor="vehicle_type" style={{ marginTop: 10 }}>
          Vehicle Type
        </InputLabel>
        <Select
          style={{ marginTop: 10 }}
          native
          value={state.dev.vehicle_type}
          onChange={handleChange}
          inputProps={{
            name: "vehicle_type",
            id: "vehicle_type",
          }}
          fullWidth
        >
          {VEHICLE_TYPE.map((item, index) => (
            <option key={index} value={item.value}>
              {item.name}
            </option>
          ))}
        </Select>

        <FormControlLabel  control={<Checkbox checked={state.dev.over_due} onChange={handleCheckChange} />} label="Overdue Payment" />
      </DialogContent>

      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateDevice: (newData, oldData, firebase, resolve) =>
      dispatch(updateDevice(newData, oldData, firebase, resolve)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFirebase(withDevice(UpdateDevice)));
