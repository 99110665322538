import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { withDevice } from '../device';
import { withFirebase } from '../../Firebase';
import { connect } from 'react-redux';
import { userSharedDevices, deleteUserSharedDevice } from '../../../actions';
import { MAP_API_KEY } from '../../../utils/web_utils';
import ShareMarker from './gmap/share_marker';
import SharedVehicleOverlay from './shared_vehicle_overlay';
import SharedVehicleInfo from './shared_vehicle_info';
import { ACTIVE_STATUS_DAY } from '../../../utils/web_utils';
import { getCenter } from '../../../utils/utils';

let defaultLatLng = {
  lat: 23.8103,
  lng: 90.4125,
};

const SharedVehiclesInMap = ({
  deviceParam,
  getUserSharedDevices,
  firebase,
  sharedDevices,
  authUser,
}) => {
  const { setTitle } = deviceParam;

  const [state, setState] = useState({
    search: '',
    center: { ...defaultLatLng },
    zoom: 15,
    searchDevice: [],
    selectedDevice: null,
    open: false,
    status: null,
  });
  useEffect(() => {
    setTitle('Shared Vehicles in Map');

    if (sharedDevices.length === 0) {
      getUserSharedDevices(authUser.webuid, firebase);
    }
  }, []);

  const setSelectedDevice = (device) => {
    setState((old) => ({ ...old, selectedDevice: device }));
  };

  useEffect(() => {
    setState((old) => ({
      ...old,
      searchDevice: [...sharedDevices].filter((x) => x.geo),
    }));
  }, [sharedDevices]);

  useEffect(() => {
    setState((old) => ({
      ...old,
      center: state.selectedDevice
        ? {
            lat: state.selectedDevice.geo.lat,
            lng: state.selectedDevice.geo.lng,
          }
        : averageGeolocation([...state.searchDevice].filter((x) => x.geo)),
      zoom: state.selectedDevice ? 17 : state.searchDevice.length > 1 ? 12 : 15,
    }));
  }, [state.searchDevice, state.selectedDevice]);

  const averageGeolocation = (devices) => {
    if (devices.length === 1) {
      return { lat: devices[0].geo.lat, lng: devices[0].geo.lng };
    } else if (devices.length === 0) {
      return defaultLatLng;
    }
    return getCenter(devices);
  };

  const infoCardClick = (val) => {
    // console.log(val);
    if (val === 0) {
      setState((old) => ({
        ...old,
        searchDevice: [...sharedDevices]
          .filter((dev) => dev.geo)
          .filter(
            (dev) =>
              dev.geo.update_time &&
              Math.floor(
                (new Date() - new Date(dev.geo.update_time)) /
                  (1000 * 60 * 60 * 24)
              ) <= ACTIVE_STATUS_DAY
          ),
      }));
    } else if (val === 1) {
      setState((old) => ({
        ...old,
        searchDevice: [...sharedDevices]
          .filter((dev) => dev.geo)
          .filter(
            (dev) =>
              dev.geo.update_time &&
              Math.floor(
                (new Date() - new Date(dev.geo.update_time)) /
                  (1000 * 60 * 60 * 24)
              ) > ACTIVE_STATUS_DAY
          ),
      }));
    } else if (val === 2) {
      setState((old) => ({
        ...old,
        searchDevice: [...sharedDevices].filter(
          (x) => x.geo && x.geo.acc === 'ON'
        ),
      }));
    } else if (val === 3) {
      setState((old) => ({
        ...old,
        searchDevice: [...sharedDevices].filter(
          (x) => x.geo && x.geo.acc === 'OFF'
        ),
      }));
    }
  };

  const handleShowAllVehiclesClick = () => {
    setState((old) => ({
      ...old,
      selectedDevice: null,
      searchDevice: [...sharedDevices].filter((x) => x.geo),
    }));
  };

  const setCenter = (data) => {
    setState((old) => ({
      ...old,
      center: { lat: data.lat, lng: data.lng },
      status: data,
    }));
  };

  const setStatus = (data) => {
    setState((old) => ({ ...old, status: data }));
  };

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <div
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <LoadScript googleMapsApiKey={MAP_API_KEY}>
          <GoogleMap
            mapContainerStyle={{ width: '100%', height: '100%' }}
            center={state.center}
            zoom={state.zoom}
          >
            {state.selectedDevice ? (
              <ShareMarker
                device={state.selectedDevice}
                setCenter={setCenter}
                setStatus={setStatus}
              />
            ) : (
              state.searchDevice.map((x) => (
                <ShareMarker key={x.id} device={x} />
              ))
            )}
          </GoogleMap>
        </LoadScript>
      </div>

      <SharedVehicleOverlay
        setSelectedDevice={setSelectedDevice}
        selectedDevice={state.selectedDevice}
      />

      <SharedVehicleInfo
        selectedDevice={state.selectedDevice}
        status={state.status}
        sharedDevices={sharedDevices}
        infoCardClick={infoCardClick}
        handleShowAllVehiclesClick={handleShowAllVehiclesClick}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserSharedDevices: (uid, firebase) =>
      dispatch(userSharedDevices(uid, firebase)),
    deleteUserSharedDevice: (sharedDevice, resolve, firebase) =>
      dispatch(deleteUserSharedDevice(sharedDevice, resolve, firebase)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFirebase(withDevice(SharedVehiclesInMap)));
