/* global google */
import React, { useState, useEffect, useRef } from "react";
import { Marker } from "@react-google-maps/api";
import { withFirebase } from '../../../Firebase'
import * as geolib from "geolib";
import MyInfoWindow from './my_info_window'
import MyPolyline from './my_polyline'




const LocationMarker = ({ firebase, device }) => {

    const [geo, setGeo] = useState(null);
    const [rotation, setRotation] = useState(0);
    const [visible, setVisible] = useState(false);
    const [locations,setLocations] = useState([])

    const myRef = useRef();


    const removeListener = (listener) => {
        if (listener) {
            listener.off();
        }
    };


    const update = (nn) => {
        setGeo((old) => nn);
    };


    const animate = (newGeo) => {
        let oldGeo = null;

        myRef.current ? (oldGeo = { ...myRef.current }) : (oldGeo = { ...geo });
        let step = 20;
        let deltaLat = (newGeo.lat - oldGeo.lat) / step;
        let deltaLng = (newGeo.lng - oldGeo.lng) / step;

        let rotation = geolib.getGreatCircleBearing(
            {
                latitude: oldGeo.lat,
                longitude: oldGeo.lng,
            },
            { latitude: newGeo.lat, longitude: newGeo.lng }
        );

        setRotation(rotation);

        const anim = () => {
            if (step > 0) {
                update({
                    lat: oldGeo.lat + (21 - step) * deltaLat,
                    lng: oldGeo.lng + (21 - step) * deltaLng,
                });
                requestAnimationFrame(anim);
                step--;
            } else {
                myRef.current = { ...newGeo };

                setGeo((old) => {
                    let tt = { ...old };
                    tt.acc = newGeo.acc;
                    tt.update_time = newGeo.update_time;
                    tt.speed = newGeo.speed;
                    tt.fuel_line = newGeo.fuel_line;
                    tt.charging = newGeo.charging;
                    tt.pto_io_status = newGeo.pto_io_status;
                    tt.over_speed = newGeo.over_speed;
                    tt.temperature = newGeo.temperature;
                    tt.number_of_satellite = newGeo.number_of_satellite;
                    tt.voltage_level = newGeo.voltage_level;
                    return tt;
                });
            }
        };

        requestAnimationFrame(anim);
    };


    useEffect(() => {
        const ref = firebase.singleDeviceRef(device.id);

        ref.on("child_added", (snapshot) => {
            if (snapshot.key === "geo") {
                let data = snapshot.val();
                myRef.current = { ...data };
                setGeo({ ...data });
                setLocations(old=>[...old,data])
            }
        });

        ref.on("child_changed", (snapshot) => {
            let data = snapshot.val();
            animate(data);
            setLocations(old=>[...old,data])
        });
        return () => removeListener(ref);
    }, []);


    return (
        <div >
            {geo && (
                <Marker
                    alt="Vehicle Location"
                    position={{ lat: geo.lat, lng: geo.lng }}
                    clickable={true}
                    onClick={() => setVisible(true)}
                    icon={{
                        path: "M29.395,0H17.636c-3.117,0-5.643,3.467-5.643,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759   c3.116,0,5.644-2.527,5.644-5.644V6.584C35.037,3.467,32.511,0,29.395,0z M34.05,14.188v11.665l-2.729,0.351v-4.806L34.05,14.188z    M32.618,10.773c-1.016,3.9-2.219,8.51-2.219,8.51H16.631l-2.222-8.51C14.41,10.773,23.293,7.755,32.618,10.773z M15.741,21.713   v4.492l-2.73-0.349V14.502L15.741,21.713z M13.011,37.938V27.579l2.73,0.343v8.196L13.011,37.938z M14.568,40.882l2.218-3.336   h13.771l2.219,3.336H14.568z M31.321,35.805v-7.872l2.729-0.355v10.048L31.321,35.805z",
                        fillColor: "black",

                        fillOpacity: 0.9,
                        strokeColor: geo.acc === "ON" ? "green" : "red",
                        strokeWeight: 2,
                        rotation: rotation,
                        anchor: new google.maps.Point(25, 25),
                    }}
                />

                
            )}

            {locations.length>0 && <MyPolyline locations={locations} />}

            {visible && <MyInfoWindow device={device} geo={locations[locations.length-1]} onMarkerClick={setVisible}/>}
        </div>
    )

}

export default withFirebase(LocationMarker)