import React, { useState, useEffect } from 'react';

import { withDevice } from '../device';
import { withFirebase } from '../../Firebase';
import { connect } from 'react-redux';
import { userSharedDevices, deleteUserSharedDevice } from '../../../actions';
import { grey } from '@material-ui/core/colors';
import { SharedDeviceTable } from './virtual_table';
import {
  Card,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import 'react-virtualized/styles.css';
import { Search, Clear } from '@material-ui/icons';

const SharedDevices = ({
  deviceParam,
  authUser,
  firebase,
  sharedDevices,
  deleteUserSharedDevice,
  getUserSharedDevices,
}) => {
  const {
    setTitle,
    setDevice,
    handleMapClick,
    reportClick,
    monthlyTemperatureLog,
    monthlyPTIOReport,
    dailyTravelReportClick,
    dailySpeedReportClick,
    dailyReportClick,
    dailyRouteSummeryClick,
    fromSharedUser,
  } = deviceParam;

  const [state, setState] = useState({
    search: '',
    devices: [],
  });

  const setSearch = (val) => {
    setState((old) => ({
      ...old,
      search: val,
      devices: [...sharedDevices].filter((x) => x.id.includes(val)),
    }));
  };

  const handleChange = (e) => {
    let val = e.target.value;
    setState((old) => ({
      ...old,
      search: val,
      devices: [...sharedDevices].filter(
        (x) =>
          x.id.includes(val) ||
          (x.registration_number &&
            x.registration_number.toLowerCase().includes(val.toLowerCase()))
      ),
    }));
  };

  useEffect(() => {
    setTitle('Shared Vehicle List');

    if (sharedDevices.length === 0) {
      getUserSharedDevices(authUser.webuid, firebase);
    }
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        padding: 10,
        backgroundColor: grey[300],
      }}
    >
      <div style={{ width: '100%', marginBottom: 20 }}>
        <Card style={{ width: '100%', height: '100%' }}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '100%',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: 10,
                alignItems: 'center',
              }}
            >
              <Typography variant="caption">
                Total Shared Devices ({sharedDevices.length})
              </Typography>
              <TextField
                placeholder="Search"
                onChange={handleChange}
                name="search"
                value={state.search}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search
                        style={{
                          color: grey[700],
                          padding: 4,
                          cursor: 'pointer',
                        }}
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setSearch('')}>
                        <Clear
                          style={{
                            color: grey[700],
                            padding: 4,
                            cursor: 'pointer',
                          }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
              <div style={{ width: '98%' }}>
                <SharedDeviceTable
                  devices={
                    state.devices.length > 0 ? state.devices : sharedDevices
                  }
                  dailyReportClick={dailyReportClick}
                  setDevice={setDevice}
                  handleMapClick={handleMapClick}
                  reportClick={reportClick}
                  monthlyTemperatureLog={monthlyTemperatureLog}
                  monthlyPTIOReport={monthlyPTIOReport}
                  dailyTravelReportClick={dailyTravelReportClick}
                  dailySpeedReportClick={dailySpeedReportClick}
                  dailyRouteSummeryClick={dailyRouteSummeryClick}
                />
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
    //   <SharedDevicesTable
    //     sharedDevices={filteredDevices()}
    //     handleMapClick={handleMapClick}
    //     firebase={firebase}
    //     deleteUserSharedDevice={deleteUserSharedDevice}
    //     reportClick={reportClick}
    //     sharedDevicesDailyReport={sharedDevicesDailyReport}
    //   />
    // </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserSharedDevices: (uid, firebase) =>
      dispatch(userSharedDevices(uid, firebase)),
    deleteUserSharedDevice: (sharedDevice, resolve, firebase) =>
      dispatch(deleteUserSharedDevice(sharedDevice, resolve, firebase)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFirebase(withDevice(SharedDevices)));
