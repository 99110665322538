import React, { useState } from 'react';
import { Column, Table, AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css';
import dateformat from 'dateformat';
import {
  Typography,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { grey, orange } from '@material-ui/core/colors';
import { MoreVert } from '@material-ui/icons';

const headerStyle = {
  color: grey[700],
};

const rowStyle = (index) => {
  if (index % 2 === 0) {
    return { backgroundColor: grey[100] };
  } else {
    return { backgroundColor: grey[300] };
  }
};

const getSuffix = (n) => {
  if (n >= 11 && n <= 13) {
    return 'th';
  }
  switch (n % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

const getDate = (cellData) => {
  return (
    String(cellData.day).padStart(2, 0) +
    '-' +
    String(cellData.month + 1).padStart(2, 0) +
    '-' +
    String(cellData.year)
  );
};

const getTime = (seconds) => {
  let hr = null;
  let min = null;
  let sec = null;
  if (seconds >= 3600) {
    hr = parseInt(seconds / 3600);
    let remaining = seconds - hr * 3600;
    min = parseInt(remaining / 60);

    return hr + ' hrs ' + min + ' min';
  } else {
    min = parseInt(seconds / 60);
    sec = seconds - min * 60;

    return min + ' min ' + sec + ' sec';
  }
};

const formatTime = (timeStr) => {
  let arr = timeStr.split(':');
  let hr = Number(arr[0]);
  let min = Number(arr[1]);
  let suffix = '';
  if (Math.floor(hr / 12) == 0) {
    suffix = ' AM';
  } else {
    hr = hr - 12;
    suffix = ' PM';
  }
  if (hr === 0) {
    hr = 12;
  }
  return String(hr).padStart(2, 0) + ':' + String(min).padStart(2, 0) + suffix;
};

export const formatDuration = (time) => {
  let hr = Math.floor(time / 3600);
  let rem = time % 3600;
  let min = Math.floor(rem / 60);

  return (
    String(hr).padStart(2, 0) + ' hrs ' + String(min).padStart(2, 0) + ' mins'
  );
};

export const TripReport = ({ trip_report }) => {
  return (
    <AutoSizer>
      {({ width, height }) => (
        <Table
          width={width}
          height={height}
          headerHeight={50}
          rowHeight={50}
          rowCount={trip_report.length}
          rowGetter={({ index }) => trip_report[index]}
          rowStyle={({ index }) => rowStyle(index)}
          headerStyle={headerStyle}
        >
          <Column
            width={60}
            flexGrow={2}
            label="STATUS"
            dataKey="status"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">
                {cellData === 'OFF' ? 'STOPPED' : 'RUNNING'}
              </Typography>
            )}
          />

          <Column
            width={60}
            flexGrow={3}
            label="START"
            dataKey="start"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">
                {cellData ? formatTime(cellData) : 'undefined'}
              </Typography>
            )}
          />

          <Column
            width={60}
            flexGrow={3}
            label="STOP"
            dataKey="end"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">
                {cellData ? formatTime(cellData) : 'undefined'}
              </Typography>
            )}
          />

          <Column
            width={60}
            flexGrow={3}
            label="DURATION"
            dataKey="duration"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">
                {cellData ? formatDuration(cellData) : formatDuration(0)}
              </Typography>
            )}
          />

          <Column
            width={60}
            flexGrow={3}
            label="DISTANCE"
            dataKey="distance"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">
                {cellData ? String(cellData).padEnd(2, 0) + ' KM' : '0.00 KM'}
              </Typography>
            )}
          />
        </Table>
      )}
    </AutoSizer>
  );
};

export const HourlyReport = ({ hourly_report }) => {
  return (
    <AutoSizer>
      {({ width, height }) => (
        <Table
          width={width}
          height={height}
          headerHeight={50}
          rowHeight={50}
          rowCount={hourly_report.length}
          rowGetter={({ index }) => hourly_report[index]}
          rowStyle={({ index }) => rowStyle(index)}
          headerStyle={headerStyle}
        >
          <Column
            width={60}
            flexGrow={2}
            label="HOUR"
            dataKey="_id"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">
                {cellData + 1 + getSuffix(cellData + 1) + ' Hr'}
              </Typography>
            )}
          />

          <Column
            width={60}
            flexGrow={3}
            label="START"
            dataKey="start"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">
                {cellData ? formatTime(cellData) : 'undefined'}
              </Typography>
            )}
          />

          <Column
            width={60}
            flexGrow={3}
            label="STOP"
            dataKey="end"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">
                {cellData ? formatTime(cellData) : 'undefined'}
              </Typography>
            )}
          />

          <Column
            width={60}
            flexGrow={3}
            label="DISTANCE"
            dataKey="distance"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">
                {cellData ? String(cellData).padEnd(2, 0) + ' KM' : '0.00 KM'}
              </Typography>
            )}
          />
        </Table>
      )}
    </AutoSizer>
  );
};

export const SharedDeviceTable = ({
  devices,
  dailyReportClick,
  setDevice,
  handleMapClick,
  reportClick,
  monthlyTemperatureLog,
  monthlyPTIOReport,
  dailyTravelReportClick,
  dailySpeedReportClick,
  dailyRouteSummeryClick,
}) => {
  const [state, setState] = useState({
    device: null,
    anchorEl: null,
  });

  const open = Boolean(state.anchorEl);

  const handleClick = (e, device) => {
    setState((old) => ({ ...old, device: device, anchorEl: e.currentTarget }));
  };

  const handleClose = () => {
    setState((old) => ({ ...old, device: null, anchorEl: null }));
  };

  const mapClick = () => {
    handleMapClick({ ...state.device });
  };

  const hanleDailyReportClick = () => {
    dailyReportClick({ ...state.device }, true);
  };

  const handleReportClick = () => {
    reportClick({ ...state.device }, true);
  };

  const handleDailyRouteSummeryClick = () => {
    dailyRouteSummeryClick({ ...state.device }, true);
  };

  const handleDailyTravelReportClick = () => {
    dailyTravelReportClick({ ...state.device }, true);
  };

  const handleDailySpeedReportClick = () => {
    dailySpeedReportClick({ ...state.device }, true);
  };

  const handleMonthlyTemperatureLogClick = () => {
    monthlyTemperatureLog({ ...state.device });
  };

  const handlePTIOReport = () => {
    monthlyPTIOReport({ ...state.device });
  };

  return (
    <AutoSizer>
      {({ width, height }) => (
        <Table
          width={width}
          height={height}
          headerHeight={50}
          rowHeight={50}
          rowCount={devices.length}
          rowGetter={({ index }) => devices[index]}
          rowStyle={({ index }) => rowStyle(index)}
          headerStyle={headerStyle}
        >
          <Column
            width={60}
            flexGrow={3}
            label="ID"
            dataKey="id"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">{cellData}</Typography>
            )}
          />

          <Column
            width={60}
            flexGrow={3}
            label="Reg Number"
            dataKey="registration_number"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">
                {cellData ? cellData : 'undefined'}
              </Typography>
            )}
          />

          <Column
            width={60}
            flexGrow={2}
            label="MODEL"
            dataKey="vehicle_model"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">
                {cellData ? cellData : 'undefined'}
              </Typography>
            )}
          />

          <Column
            width={60}
            flexGrow={2}
            label="STATUS"
            dataKey="geo"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">
                {cellData && cellData.acc && cellData.acc === 'ON'
                  ? 'RUNNING'
                  : 'STOPPED'}
              </Typography>
            )}
          />

          <Column
            width={60}
            flexGrow={2}
            label="RELAY"
            dataKey="geo"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">
                {cellData && cellData.fuel_line && cellData.fuel_line === 'ON'
                  ? 'ON'
                  : 'OFF'}
              </Typography>
            )}
          />

          <Column
            width={60}
            flexGrow={2}
            label="CHARGING"
            dataKey="geo"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">
                {cellData && cellData.charging && cellData.charging === 'ON'
                  ? 'ON'
                  : 'OFF'}
              </Typography>
            )}
          />

          <Column
            width={60}
            flexGrow={3}
            label="VOLTAGE"
            dataKey="geo"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">
                {cellData && cellData.voltage_level
                  ? cellData.voltage_level
                  : 'undefined'}
              </Typography>
            )}
          />

          <Column
            width={60}
            flexGrow={2}
            label="SPEED"
            dataKey="geo"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">
                {cellData && cellData.speed ? cellData.speed + ' K/H' : '0 K/H'}
              </Typography>
            )}
          />

          <Column
            width={60}
            flexGrow={2}
            label="SATELLITE"
            dataKey="geo"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">
                {cellData && cellData.number_of_satellite
                  ? cellData.number_of_satellite
                  : 0}
              </Typography>
            )}
          />

          <Column
            headerStyle={{ textAlign: 'right', marginRight: 20 }}
            width={60}
            flexGrow={2}
            label="Action"
            dataKey="action"
            cellRenderer={({ rowData }) => (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Tooltip title="More..">
                  <IconButton
                    onClick={(e) => handleClick(e, rowData)}
                    style={{ marginLeft: 2, marginRight: 2 }}
                  >
                    <MoreVert />
                  </IconButton>
                </Tooltip>

                <Menu
                  id="basic-menu"
                  anchorEl={state.anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={mapClick}>Live Tracking</MenuItem>
                  <MenuItem onClick={hanleDailyReportClick}>
                    Daily Report
                  </MenuItem>
                  <MenuItem onClick={handleReportClick}>
                    Monthly Report
                  </MenuItem>
                  <MenuItem onClick={handleDailyTravelReportClick}>
                    Daily Travel Report
                  </MenuItem>
                  <MenuItem onClick={handleDailySpeedReportClick}>
                    Speed Report
                  </MenuItem>

                  <MenuItem onClick={handleDailyRouteSummeryClick}>
                    Route Summery
                  </MenuItem>

                  {state.device && state.device.device_model === 'S5E' && (
                    <MenuItem onClick={handleMonthlyTemperatureLogClick}>
                      Monthly Temperature Log
                    </MenuItem>
                  )}

                  {state.device && state.device.device_model === 'S5E' && (
                    <MenuItem onClick={handlePTIOReport}>PTIO Report</MenuItem>
                  )}
                </Menu>
              </div>
            )}
          />
        </Table>
      )}
    </AutoSizer>
  );
};

export const MonthlyReportTable = ({ monthly_data, fuelRequired, device }) => {
  // console.log(monthly_data)

  return (
    <AutoSizer>
      {({ width, height }) => (
        <Table
          width={width}
          height={height}
          headerHeight={50}
          rowHeight={50}
          rowCount={monthly_data.length}
          rowGetter={({ index }) => monthly_data[index]}
          rowStyle={({ index }) => rowStyle(index)}
          headerStyle={headerStyle}
        >
          <Column
            width={80}
            flexGrow={1}
            label="Date"
            dataKey="_id"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">{getDate(cellData)}</Typography>
            )}
          />

          <Column
            width={80}
            flexGrow={1}
            label="Start"
            dataKey="start_time"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">
                {dateformat(cellData, 'hh:MM TT')}
              </Typography>
            )}
          />
          <Column
            width={80}
            flexGrow={1}
            label="Stop"
            dataKey="end_time"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">
                {dateformat(cellData, 'hh:MM TT')}
              </Typography>
            )}
          />

          <Column
            width={80}
            flexGrow={1}
            label="Running"
            dataKey="running_time"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">
                {formatDuration(cellData)}
              </Typography>
            )}
          />

          <Column
            width={80}
            flexGrow={1}
            label="Idle"
            dataKey="idle_time"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">
                {formatDuration(cellData)}
              </Typography>
            )}
          />

          <Column
            width={80}
            flexGrow={1}
            label="Congestion"
            dataKey="congestion_time"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">
                {formatDuration(cellData)}
              </Typography>
            )}
          />

          <Column
            width={80}
            flexGrow={1}
            label="Distance(km)"
            dataKey="distance"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">
                {(cellData / 1000).toFixed(2)}
              </Typography>
            )}
          />

          {device.mileage && (
            <Column
              width={80}
              flexGrow={1}
              label="Fuel(km)"
              dataKey="distance"
              cellRenderer={({ rowData }) => (
                <Typography variant="caption">
                  {fuelRequired(rowData.distance, rowData.congestion_time)}
                </Typography>
              )}
            />
          )}
        </Table>
      )}
    </AutoSizer>
  );
};

export const RouteSummeryTable = ({ datas }) => {
  return (
    <AutoSizer>
      {({ width, height }) => (
        <Table
          width={width}
          height={height}
          headerHeight={50}
          rowHeight={50}
          rowCount={datas.length}
          rowGetter={({ index }) => datas[index]}
          rowStyle={({ index }) => rowStyle(index)}
          headerStyle={headerStyle}
        >
          <Column
            width={80}
            flexGrow={1}
            label="Start Address"
            dataKey="start_address"
            cellRenderer={({ cellData }) => (
              <Tooltip title={cellData}>
                <Typography variant="caption">{cellData}</Typography>
              </Tooltip>
            )}
          />

          <Column
            width={80}
            flexGrow={1}
            label="StartAt"
            dataKey="start_time"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">{cellData}</Typography>
            )}
          />
          <Column
            width={80}
            flexGrow={1}
            label="Distance(KM)"
            dataKey="distance"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">{cellData}</Typography>
            )}
          />
          <Column
            width={80}
            flexGrow={1}
            label="Duration"
            dataKey="duration"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">{cellData}</Typography>
            )}
          />
          <Column
            width={80}
            flexGrow={1}
            label="EndAt"
            dataKey="end_time"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">{cellData}</Typography>
            )}
          />
          <Column
            width={80}
            flexGrow={1}
            label="End Address"
            dataKey="end_address"
            cellRenderer={({ cellData }) => (
              <Tooltip title={cellData}>
                <Typography variant="caption">{cellData}</Typography>
              </Tooltip>
            )}
          />
        </Table>
      )}
    </AutoSizer>
  );
};

export const AdminTable = ({ devices }) => {
  return (
    <AutoSizer>
      {({ width, height }) => (
        <Table
          width={width}
          height={height}
          headerHeight={50}
          rowHeight={50}
          rowCount={devices.length}
          rowGetter={({ index }) => devices[index]}
          rowStyle={({ index }) => rowStyle(index)}
          headerStyle={headerStyle}
        >
          <Column
            width={60}
            flexGrow={2}
            label="ID"
            dataKey="id"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">
                {cellData ? cellData : 'undefined'}
              </Typography>
            )}
          />

          <Column
            width={60}
            flexGrow={3}
            label="Reg No"
            dataKey="registration_number"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">
                {cellData ? cellData : 'undefined'}
              </Typography>
            )}
          />
        </Table>
      )}
    </AutoSizer>
  );
};

export const CollectionTable = ({ data, onCheckClick }) => {
  return (
    <AutoSizer>
      {({ width, height }) => (
        <Table
          width={width}
          height={height}
          headerHeight={50}
          rowHeight={50}
          rowCount={data.length}
          rowGetter={({ index }) => data[index]}
          rowStyle={({ index }) => rowStyle(index)}
          headerStyle={headerStyle}
        >
          <Column
            width={60}
            flexGrow={2}
            label="ID"
            dataKey="device_id"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">
                {cellData ? cellData : 'undefined'}
              </Typography>
            )}
          />

          <Column
            width={60}
            flexGrow={3}
            label="Reg No"
            dataKey="registration_number"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">
                {cellData ? cellData : 'undefined'}
              </Typography>
            )}
          />
          <Column
            width={60}
            flexGrow={3}
            label="customer email"
            dataKey="customer_email"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">
                {cellData ? cellData : 'undefined'}
              </Typography>
            )}
          />

          <Column
            width={60}
            flexGrow={3}
            label="customer number"
            dataKey="customer_number"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">
                {cellData ? cellData : 'undefined'}
              </Typography>
            )}
          />

          <Column
            width={60}
            flexGrow={3}
            label="service charge"
            dataKey="service_charge"
            cellRenderer={({ cellData }) => (
              <Typography variant="caption">
                {cellData ? cellData : 'undefined'}
              </Typography>
            )}
          />

          <Column
            width={60}
            flexGrow={3}
            label="payment status"
            dataKey="payment_status"
            cellRenderer={({ cellData, rowData }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={cellData}
                    checked={cellData}
                    onClick={() => onCheckClick(rowData)}
                  />
                }
                label={cellData ? 'Paid' : 'Due'}
              />
            )}
          />
        </Table>
      )}
    </AutoSizer>
  );
};
