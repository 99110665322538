import React, { useState, useRef, useEffect } from 'react';
import {
  IconButton,
  Slide,
  TextField,
  InputAdornment,
  ListItem,
  ListItemText,
  List,
} from '@material-ui/core';
import { ExpandLess, ExpandMore, Search, Clear } from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';
import { connect } from 'react-redux';

const SharedVehicleOverlay = ({
  sharedDevices,
  setSelectedDevice,
  selectedDevice,
}) => {
  const [state, setState] = useState({
    open: false,
    search: '',
    searchedDevice: [],
  });

  const containerRef = useRef(null);

  const handleChange = (e) => {
    e.persist();
    setState((old) => ({
      ...old,
      search: e.target ? e.target.value : '',
    }));
  };

  //   useEffect(() => {
  //     setState((old) => ({ ...old, searchedDevice: [...sharedDevices] }));
  //   }, [sharedDevices]);

  useEffect(() => {
    const deviceFilter = (device) => {
      return (
        (device.id &&
          device.id.toLowerCase().includes(state.search.toLowerCase())) ||
        (device.mask_id &&
          device.mask_id.toLowerCase().includes(state.search.toLowerCase())) ||
        (device.registration_number &&
          device.registration_number
            .toLowerCase()
            .includes(state.search.toLowerCase())) ||
        (device.vehicle_model &&
          device.vehicle_model
            .toLowerCase()
            .includes(state.search.toLowerCase())) ||
        (device.device_sim_number &&
          device.device_sim_number
            .toLowerCase()
            .includes(state.search.toLowerCase())) ||
        (device.chasis_number &&
          device.chasis_number
            .toLowerCase()
            .includes(state.search.toLowerCase())) ||
        (device.code &&
          device.code.toLowerCase().includes(state.search.toLowerCase())) ||
        (device.driver_name &&
          device.driver_name
            .toLowerCase()
            .includes(state.search.toLowerCase())) ||
        (device.driver_phone &&
          device.driver_phone
            .toLowerCase()
            .includes(state.search.toLowerCase()))
      );
    };

    if (state.search === '') {
      setState((old) => ({ ...old, searchedDevice: [...sharedDevices] }));
    } else {
      setState((old) => ({
        ...old,
        searchedDevice: [...sharedDevices].filter((x) => deviceFilter(x)),
      }));
    }
  }, [state.search, sharedDevices]);

  return (
    <div
      style={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '20%',
        height: '50%',
        zIndex: 500,
      }}
      ref={containerRef}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column-reverse',
        }}
      >
        {state.open && (
          <Slide
            direction="up"
            in={state.open}
            container={containerRef.current}
            mountOnEnter
            unmountOnExit
          >
            <div
              style={{
                display: 'flex',
                flex: 1,
                backgroundColor: 'white',
                overflow: 'auto',
              }}
            >
              <List style={{ width: '100%' }}>
                {state.searchedDevice.map((item, index) => (
                  <ListItem
                    button
                    key={index}
                    divider
                    onClick={() => setSelectedDevice(item)}
                    selected={selectedDevice && item.id === selectedDevice.id}
                  >
                    <ListItemText style={{ textTransform: 'uppercase' }}>
                      {item.registration_number}
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </div>
          </Slide>
        )}

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: 'white',
            alignItems: 'center',
            padding: 10,
          }}
        >
          <TextField
            style={{ flex: 1 }}
            placeholder="Search vehicle by reg. number"
            onChange={handleChange}
            name="search"
            value={state.search}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search
                    style={{
                      color: grey[700],
                      padding: 4,
                      cursor: 'pointer',
                    }}
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setState((old) => ({ ...old, search: '' }))}
                  >
                    <Clear
                      style={{
                        color: grey[700],
                        padding: 4,
                        cursor: 'pointer',
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <IconButton
            onClick={() => setState((old) => ({ ...old, open: !old.open }))}
          >
            {state.open ? <ExpandMore /> : <ExpandLess />}
          </IconButton>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(SharedVehicleOverlay);
