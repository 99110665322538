import { Box, Card, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { withDevice } from '../../device';
import MyDatePicker from './date_picker';
import UserDeviceAutoComplete from './user_device_autocomplete';

import {
  GoogleMap,
  LoadScript,
  InfoWindow,
  Marker,
} from '@react-google-maps/api';
import { MAP_API_KEY } from '../../../../utils/web_utils';
import DailyPolyline from './daily_polyline';

import axios from 'axios';
import { DAILY_LOCATIONS_URL } from '../../../../utils/url';

import marker_blue from '../../../../images/marker_blue.png';
import marker_red from '../../../../images/marker_red.png';
import marker_green from '../../../../images/marker_green.png';
import { getDiatance } from './utils';
import TripPopup from './trip-popup';
import { AUTHORIZATION_KEY } from '../../../../utils/utils';

const bb = {
  left: 0,
  top: 0,
  zIndex: 20000,
  padding: 10,
};

let defaultLatLng = {
  lat: 23.8103,
  lng: 90.4125,
};

const containerStyle = {
  height: '100%',
  width: '100%',
};

const DailyReport = ({ deviceParam }) => {
  const { setTitle } = deviceParam;

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [device, setDevice] = useState(null);

  const [distance, setDistance] = useState(0);

  const [center, setCenter] = useState({
    lat: defaultLatLng.lat,
    lng: defaultLatLng.lng,
  });
  const [locations, setLocations] = useState([]);

  const [location, setLocation] = useState(null);

  useEffect(() => {
    setTitle('Daily Travel Report');
  }, []);

  useEffect(() => {
    const updateDistance = () => {
      let dist = 0;
      let ll = [...locations];

      for (let i = 0; i < ll.length; i++) {
        if (i > 0) {
          dist = dist + getDiatance(ll[i - 1], ll[i]);
        }
      }

      dist = (dist / 1000).toFixed(2);

      setDistance(dist);
    };

    ///Original Code

    if (locations.length > 0) {
      setCenter({ lat: locations[0].geo.lat, lng: locations[0].geo.lng });
    } else {
      setCenter({ lat: defaultLatLng.lat, lng: defaultLatLng.lng });
    }

    updateDistance();
  }, [locations]);

  useEffect(() => {
    if (device && selectedDate) {
      let postData = {
        device_id: device.id,
        year: selectedDate.getFullYear(),
        month: selectedDate.getMonth(),
        day: selectedDate.getDate(),
        device_type: device.vehicle_type,
      };

      console.log(postData);
      axios
        .post(DAILY_LOCATIONS_URL, postData, {
          headers: {
            Authorization: AUTHORIZATION_KEY,
          },
        })
        .then((response) => setLocations(response.data))
        .catch((err) => console.log(err));
    }
  }, [device, selectedDate]);

  const getOtherLocations = () => {
    let ll = [...locations].slice(1, -1);
    return ll.filter((l, index) => index % 20 === 0);
  };

  return (
    <LoadScript googleMapsApiKey={MAP_API_KEY}>
      {center && (
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={18}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            style={bb}
          >
            <MyDatePicker
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />

            <Card style={{ zIndex: 30000 }}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{
                  width: 300,
                  height: 48,
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                <Typography variant="button">
                  Travel Distance {distance} KM
                </Typography>
              </Box>
            </Card>

            <UserDeviceAutoComplete setDevice={setDevice} />
            {/* <Button onClick={animate}>Animate</Button> */}
          </Box>
          {locations.length > 0 && (
            <div>
              <DailyPolyline locations={locations} />
              <Marker
                onClick={() => setLocation(locations[0])}
                icon={{
                  url: marker_red,
                  scaledSize: new window.google.maps.Size(40, 40),
                }}
                position={{
                  lat: locations[0].geo.lat,
                  lng: locations[0].geo.lng,
                }}
              />
              {locations.length > 1 && (
                <Marker
                  onClick={() => setLocation(locations[locations.length - 1])}
                  icon={{
                    url: marker_blue,
                    scaledSize: new window.google.maps.Size(40, 40),
                  }}
                  position={{
                    lat: locations[locations.length - 1].geo.lat,
                    lng: locations[locations.length - 1].geo.lng,
                  }}
                />
              )}

              {getOtherLocations().map((loc) => (
                <Marker
                  key={loc._id}
                  onClick={() => setLocation(loc)}
                  icon={{
                    url: marker_green,
                    scaledSize: new window.google.maps.Size(30, 30),
                  }}
                  position={{
                    lat: loc.geo.lat,
                    lng: loc.geo.lng,
                  }}
                />
              ))}
            </div>
          )}

          {location && (
            <InfoWindow
              position={{ lat: location.geo.lat, lng: location.geo.lng }}
              onCloseClick={() => setLocation(null)}
            >
              <TripPopup location={location} />
            </InfoWindow>
          )}
        </GoogleMap>
      )}
    </LoadScript>
  );
};

export default withDevice(DailyReport);
