import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withFirebase } from '../../Firebase';
import { withRouter } from 'react-router-dom';
import {
  Drawer,
  List,
  Avatar,
  Typography,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
} from '@material-ui/core';
import {
  Home,
  Person,
  LocationOn,
  DriveEta,
  NotificationsActive,
  NotificationImportant,
  AttachMoney,
  ExitToApp,
  Delete,
  Assessment,
} from '@material-ui/icons';
import { LANDING } from '../../../constants/routes';

const drawerList = [
  { label: 'Home', icon: Home },
  { label: 'All Vehicles', icon: LocationOn },
  { label: 'Shared Vehicles', icon: DriveEta },
  { label: 'Shared Vehicles in Map', icon: DriveEta },
  { label: 'Profile', icon: Person },
  { label: 'Notifications', icon: NotificationsActive },
  { label: 'Alerts', icon: NotificationImportant },
  { label: 'Expenses', icon: AttachMoney },
  { label: 'UnAssign Devices', icon: Delete },
  { label: 'Daily Travel Report', icon: Assessment },
  { label: 'Inactive Devices', icon: Assessment },
  { label: 'Monthly Report', icon: Assessment },
  { label: 'Devices Daily Report', icon: Assessment },
  { label: 'Temperature Log', icon: Assessment },
  { label: 'Logout', icon: ExitToApp },
];

const useStyle = makeStyles((theme) => ({
  drawer: {
    width: 300,
    height: '100vh',
  },
  avater: {
    width: 80,
    height: 80,
  },
}));

const WebDrawer = ({
  open,
  handleClose,
  authUser,
  handleItemClick,
  firebase,
  history,
  components,
}) => {
  const classes = useStyle();
  const selected =
    components[components.length - 1] < 14
      ? components[components.length - 1]
      : 0;

  const handleClick = (index) => {
    if (index === 14) {
      firebase.doSignOut();
      localStorage.removeItem('authUser');
      history.push(LANDING);
    } else {
      handleItemClick(index);
    }
  };
  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={handleClose}
      style={{ maxHeight: '100vh' }}
    >
      <Box display="flex" flexDirection="column" className={classes.drawer}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          style={{ padding: 10 }}
        >
          {authUser && authUser.image ? (
            <Avatar src={authUser.image} className={classes.avater} />
          ) : (
            <Avatar className={classes.avater}>A</Avatar>
          )}
          <Typography variant="body1">
            {authUser ? authUser.email : 'not'}
          </Typography>
        </Box>
        <Box display="flex" flexGrow="1" alignItems="stretch">
          <List style={{ width: '100%' }}>
            {drawerList.map((item, index) => (
              <ListItem
                button
                key={index}
                divider
                onClick={() => handleClick(index)}
                selected={selected === index}
              >
                <ListItemIcon>{<item.icon color="primary" />}</ListItemIcon>
                <ListItemText style={{ textTransform: 'uppercase' }}>
                  {item.label}
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Drawer>
  );
};

export default withRouter(withFirebase(WebDrawer));
