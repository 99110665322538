import React from 'react';

import { Box, Card, Typography } from '@material-ui/core';
import { LocalTaxi } from '@material-ui/icons';
import {
  green,
  amber,
  red,
  blueGrey,
  lightBlue,
} from '@material-ui/core/colors';
import {
  getActiveCount,
  getInactiveCount,
  getRunningCount,
  getStoppedCount,
} from '../../../utils/web_utils';

const DeviceTableState = ({ userDevices, setClickState }) => {
  const connectedCount = () => userDevices.filter((dev) => dev.geo).length;
  const notConnectedCount = () => userDevices.filter((dev) => !dev.geo).length;

  // const getActiveCount = () => {
  //   const today = new Date();
  //   let count = userDevices.filter((dev) => dev.geo).filter(dev=>dev.geo.update_time && Math.floor((today-new Date(dev.geo.update_time))/(1000*60*60*24))<=31).length;
  //   return count
  // };

  const getFaultyCount = () => {
    let count = 0;
    const today = new Date();
    userDevices.map((dev) => {
      if (dev.geo && dev.geo.update_time) {
        const updateDate = new Date(dev.geo.update_time);
        const diffTime = Math.abs(today - updateDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (diffDays > 32) {
          count++;
          return true;
        } else {
          return false;
        }
      }
    });
    return count;
  };

  const getActiveExpiredCount = () => {
    let count = 0;
    const today = new Date();
    userDevices.map((dev) => {
      if (dev.geo && dev.geo.update_time) {
        const updateDate = new Date(dev.geo.update_time);
        const diffTime = Math.abs(today - updateDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (diffDays > 32) {
          count++;
          return true;
        } else {
          return false;
        }
        // return dev.geo  && count++
      }
    });
    return count;
  };

  // const getInactiveCount = () => {
  //   const today = new Date();
  //   let count = userDevices
  //     .filter((dev) => dev.geo)
  //     .filter(
  //       (dev) =>
  //         !(
  //           dev.geo.update_time &&
  //           Math.floor(
  //             (today - new Date(dev.geo.update_time)) / (1000 * 60 * 60 * 24)
  //           ) <= 31
  //         )
  //     ).length;
  //   return count;
  // };

  // const getRunningCount = () =>
  //   userDevices.filter((dev) => dev.geo && dev.geo.acc === 'ON').length;

  // const getStoppedCount = () =>
  //   userDevices.filter((dev) => dev.geo && dev.geo.acc === 'OFF').length;

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      style={{ marginBottom: 10 }}
    >
      <Box
        display="flex"
        flexDirection="column"
        style={{ cursor: 'pointer' }}
        onClick={() => setClickState(0)}
      >
        <Card
          style={{
            backgroundColor: blueGrey[300],
            width: 50,
            height: 50,
            zIndex: 10,
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ width: '100%', height: '100%' }}
          >
            <LocalTaxi style={{ color: lightBlue[700] }} />
          </Box>
        </Card>
        <Card
          style={{
            marginTop: -40,
            marginLeft: 10,
            padding: 10,
            backgroundColor: amber[500],
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            style={{ marginLeft: 40 }}
          >
            <Typography>Connected</Typography>
            <Typography>{connectedCount()}</Typography>
          </Box>
        </Card>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        style={{ cursor: 'pointer' }}
        onClick={() => setClickState(1)}
      >
        <Card
          style={{
            backgroundColor: blueGrey[300],
            width: 50,
            height: 50,
            zIndex: 10,
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ width: '100%', height: '100%' }}
          >
            <LocalTaxi style={{ color: red[700] }} />
          </Box>
        </Card>
        <Card
          style={{
            marginTop: -40,
            marginLeft: 10,
            padding: 10,
            backgroundColor: amber[500],
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            style={{ marginLeft: 40 }}
          >
            <Typography>Not-Connected</Typography>
            <Typography>{notConnectedCount()}</Typography>
          </Box>
        </Card>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        style={{ cursor: 'pointer' }}
        onClick={() => setClickState(2)}
      >
        <Card
          style={{
            backgroundColor: blueGrey[300],
            width: 50,
            height: 50,
            zIndex: 10,
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ width: '100%', height: '100%' }}
          >
            <LocalTaxi />
          </Box>
        </Card>
        <Card
          style={{
            marginTop: -40,
            marginLeft: 10,
            padding: 10,
            backgroundColor: amber[500],
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            style={{ marginLeft: 40 }}
          >
            <Typography>Active</Typography>
            <Typography>{getActiveCount(userDevices)}</Typography>
          </Box>
        </Card>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        style={{ cursor: 'pointer' }}
        onClick={() => setClickState(3)}
      >
        <Card
          style={{
            backgroundColor: blueGrey[300],
            width: 50,
            height: 50,
            zIndex: 10,
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ width: '100%', height: '100%' }}
          >
            <LocalTaxi style={{ color: red[700] }} />
          </Box>
        </Card>
        <Card
          style={{
            marginTop: -40,
            marginLeft: 10,
            padding: 10,
            backgroundColor: amber[500],
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            style={{ marginLeft: 40 }}
          >
            <Typography>Inactive</Typography>
            <Typography>{getInactiveCount(userDevices)}</Typography>
          </Box>
        </Card>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        style={{ cursor: 'pointer' }}
        onClick={() => setClickState(4)}
      >
        <Card
          style={{
            backgroundColor: blueGrey[300],
            width: 50,
            height: 50,
            zIndex: 10,
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ width: '100%', height: '100%' }}
          >
            <LocalTaxi style={{ color: green[700] }} />
          </Box>
        </Card>
        <Card
          style={{
            marginTop: -40,
            marginLeft: 10,
            padding: 10,
            backgroundColor: amber[500],
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            style={{ marginLeft: 40 }}
          >
            <Typography>Running</Typography>
            <Typography>{getRunningCount(userDevices)}</Typography>
          </Box>
        </Card>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        style={{ cursor: 'pointer' }}
        onClick={() => setClickState(5)}
      >
        <Card
          style={{
            backgroundColor: blueGrey[300],
            width: 50,
            height: 50,
            zIndex: 10,
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ width: '100%', height: '100%' }}
          >
            <LocalTaxi style={{ color: red[700] }} />
          </Box>
        </Card>
        <Card
          style={{
            marginTop: -40,
            marginLeft: 10,
            padding: 10,
            backgroundColor: amber[500],
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            style={{ marginLeft: 40 }}
          >
            <Typography>Stopped</Typography>
            <Typography>{getStoppedCount(userDevices)}</Typography>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default DeviceTableState;
