import React, { useEffect,useState } from "react";
import { withDevice } from "../device";
import {withFirebase} from '../../Firebase'

import { GoogleMap, LoadScript,TrafficLayer } from "@react-google-maps/api";
import LocationMarker from '../component/gmap/location_marker'
import {MAP_API_KEY} from '../../../utils/web_utils'


const SingleMap = ({ deviceParam,firebase }) => {
  // console.log(device);
  const { device, setTitle } = deviceParam;

  const [center,setCenter] = useState({
    lat: device.geo.lat,
    lng: device.geo.lng,
  })


  const containerStyle = {
    height: "100%",
    width: "100%",
  };


  useEffect(() => {
    setTitle("Map view of " + device.registration_number);

    const ref = firebase.singleDeviceRef(device.id).child("geo");

    ref.once('value',snapshot=>{
      let geo = snapshot.val()

      if(center.lat!= geo.lat || center.lng !=geo.lng){
        setCenter(geo)
      }
    })

    
  }, []);
  return (

    <LoadScript googleMapsApiKey={MAP_API_KEY}>
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={15}>
          <LocationMarker device={device} />
          <TrafficLayer />
        </GoogleMap>
      </LoadScript>
  );
};

export default withFirebase(withDevice(SingleMap));
