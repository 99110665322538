import React, { useEffect, useState } from 'react';
import { withDevice } from '../device';
import { Card, Tooltip, IconButton } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';
import axios from 'axios';
import UserDeviceAutoComplete from './daily-report/user_device_autocomplete';
import SharedUserDeviceAutoComplete from './daily-report/shared_user_devices_autocomplete';
import { ROUTE_SUMMERY_URL } from '../../../utils/url';
import MyDatePicker from './daily-report/date_picker';
import { RouteSummeryTable } from './virtual_table';
import { downloadRouteSummery } from '../../../utils/download';

const getRouteSummeryData = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(ROUTE_SUMMERY_URL, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => reject(err));
  });
};

const DailyRouteSummery = ({ deviceParam }) => {
  const { setTitle, fromSharedUser, device } = deviceParam;

  const [state, setState] = useState({
    device: { ...device },
    selectedDate: new Date(),
    datas: [],
  });

  const setDevice = (device) => {
    setState((old) => ({ ...old, device: device }));
  };

  useEffect(() => {
    setTitle('Daily Route Summery of ' + state.device.registration_number);
  }, [state.device]);

  useEffect(() => {
    if (state.device && state.selectedDate) {
      let data = {
        id: state.device.id,
        year: state.selectedDate.getFullYear(),
        month: state.selectedDate.getMonth(),
        day: state.selectedDate.getDate(),
      };

      getRouteSummeryData(data)
        .then((res) => setState((old) => ({ ...old, datas: res })))
        .catch((err) => console.log(err));
    }
  }, [state.device, state.selectedDate]);

  const setSelectedDate = (date) => {
    setState((oldState) => ({ ...oldState, selectedDate: date }));
  };

  const downloadFile = () => {
    console.log('Download Button Click');
    downloadRouteSummery(
      [...state.datas].map((x) => ({
        start_address: x.start_address,
        start_time: x.start_time,
        distance: x.distance,
        duration: x.duration,
        end_time: x.end_time,
        end_address: x.end_address,
      }))
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        padding: 10,
        backgroundColor: grey[300],
      }}
    >
      <div style={{ width: '100%', marginBottom: 20 }}>
        <Card square style={{ width: '100%', height: '100%' }}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '100%',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: 10,
              }}
            >
              <div>
                <MyDatePicker
                  selectedDate={state.selectedDate}
                  setSelectedDate={setSelectedDate}
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {fromSharedUser ? (
                  <SharedUserDeviceAutoComplete
                    setDevice={setDevice}
                    device={state.device}
                  />
                ) : (
                  <UserDeviceAutoComplete
                    setDevice={setDevice}
                    device={state.device}
                  />
                )}
                <Tooltip title="Download">
                  <IconButton onClick={downloadFile}>
                    <GetApp />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <RouteSummeryTable datas={state.datas} />
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default withDevice(DailyRouteSummery);
