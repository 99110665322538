import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { withFirebase } from "../../Firebase";
import { withDevice } from "../device";



const monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];
const MonthlyInactiveDevices = ({userDevices,deviceParam}) => {

    const { setTitle } = deviceParam;

    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [rowData, setRowData] = useState(null);
    

    const IN_ACTIVE_DATE = 31


    useEffect(()=>{
        setTitle("Inactive Devices")
    },[])

    useEffect(()=>{
        const today = new Date();
        let inactiveDevice = [...userDevices].filter((dev) => dev.geo).filter(dev => !(dev.geo.update_time && Math.floor((today - new Date(dev.geo.update_time)) / (1000 * 60 * 60 * 24)) <= IN_ACTIVE_DATE))

        inactiveDevice.map(x => {
            let d = new Date(x.geo.update_time)
            x['key'] = monthShortNames[d.getMonth()]+"-"+ d.getFullYear()

        })

        inactiveDevice.sort((a,b)=>{
            return new Date(a.geo.update_time) - new Date(b.geo.update_time);
        })

        setRowData(inactiveDevice)

        // let hash = inactiveDevice.reduce((p, c) => (p[c.key] ? p[c.key].push(c) : p[c.key] = [c], p), {})
        // let newData = Object.keys(hash).map(k => ({ key: k, devices: hash[k] }));
        console.log(inactiveDevice)

    },[userDevices])

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);

        const updateData = (data) => {
            setRowData(data);
        };

        // fetch('https://www.ag-grid.com/example-assets/olympic-winners.json')
        //     .then((resp) => resp.json())
        //     .then((data) => updateData(data));
    };

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <div
                id="myGrid"
                style={{
                    height: '100%',
                    width: '100%',
                }}
                className="ag-theme-alpine"
            >
                <AgGridReact
                    defaultColDef={{
                        sortable: true,
                        resizable: true,
                    }}
                    rowData={rowData}
                    groupHeaderHeight={75}
                    headerHeight={150}
                    floatingFiltersHeight={50}
                    pivotGroupHeaderHeight={50}
                    pivotHeaderHeight={100}
                    onGridReady={onGridReady}
                >
                    <AgGridColumn headerName="Monthly Inactive Devices">
                        <AgGridColumn
                            headerName="Month-Year"
                            field="key"
                            width={150}
                            suppressSizeToFit={true}
                            enableRowGroup={true}
                            rowGroupIndex={0}
                        />
                        <AgGridColumn
                            headerName="ID"
                            field="id"
                            width={120}
                            minwidth={75}
                            maxWidth={150}
                            enableRowGroup={true}
                        />
                        <AgGridColumn
                            headerName="Code"
                            field="code"
                            width={170}
                            enableRowGroup={true}
                        />
                        <AgGridColumn
                            headerName="Device SIM"
                            field="device_sim_number"
                            width={150}
                            enableRowGroup={true}
                            pivotIndex={0}
                        />
                        <AgGridColumn
                            headerName="Customer Name"
                            field="driver_name"
                            width={170}
                            enableRowGroup={true}
                        />

                        <AgGridColumn
                            headerName="Customer Phone"
                            field="driver_phone"
                            width={170}
                            enableRowGroup={true}
                        />
                        {/* <AgGridColumn
                            headerName="Gold"
                            field="gold"
                            width={60}
                            enableValue={true}
                            suppressMenu={true}
                            filter="agNumberColumnFilter"
                            aggFunc="sum"
                        />
                        <AgGridColumn
                            headerName="Silver"
                            field="silver"
                            width={60}
                            enableValue={true}
                            suppressMenu={true}
                            filter="agNumberColumnFilter"
                            aggFunc="sum"
                        />
                        <AgGridColumn
                            headerName="Bronze"
                            field="bronze"
                            width={60}
                            enableValue={true}
                            suppressMenu={true}
                            filter="agNumberColumnFilter"
                            aggFunc="sum"
                        />
                        <AgGridColumn
                            headerName="Total"
                            field="total"
                            width={60}
                            enableValue={true}
                            suppressMenu={true}
                            filter="agNumberColumnFilter"
                            aggFunc="sum"
                        /> */}
                    </AgGridColumn>
                </AgGridReact>
            </div>
        </div>
    );

}

const mapStateToProps = (state) => {
    return {
      ...state,
    };
  };

export default connect(
    mapStateToProps
  )(withFirebase(withDevice(MonthlyInactiveDevices)));