import React, { useState,useEffect } from "react";
import { InfoWindow } from "@react-google-maps/api";
import { Box, Typography } from "@material-ui/core";
import axios from 'axios'
import moment from 'moment'

// "/v1/search.php?key=YOUR_ACCESS_TOKEN&q=SEARCH_STRING&format=json"

const plurl =
  "https://eu1.locationiq.com/v1/reverse.php?key=pk.5bb41df99bb77e1a059a77cfd4a61d15";

  // "https://us1.locationiq.com/v1/search.php?key=YOUR_ACCESS_TOKEN&q=SEARCH_STRING&format=json"

const MyInfoWindow = ({ device, geo,onMarkerClick }) => {
  const [address, setAddress] = useState("");

  // console.log("GEo",geo)


  useEffect(()=>{
    let url = plurl + "&lat=" + geo.lat + "&lon=" + geo.lng + "&format=json"

    axios.get(url)
      .then(response => {
        if (response.data.display_name) {
          setAddress((old) => response.data.display_name);
        }
      })
      .catch(err => console.log(err))

  },[geo])


  const stop_duration=dateStr=>{
    let updateTime = new Date(dateStr)
    let currentDate = new Date()
    let diff = Math.floor( (currentDate.getTime()-updateTime.getTime())/1000 )

    if(diff<=30){
      return "RUNNING"
    }else if(diff>30 && diff<60){
      return diff+" seconds ago"
    }else if(diff>60 && diff<3600){
      let min = Math.floor(diff/60)
      let sec = (diff%60)
      return min+" min "+sec+" secs ago"
    }else if(diff>3600 && diff<3600*24){
      let hr = Math.floor(diff/3600)
      let rem = (diff%3600)
      let min = Math.floor(rem/60)
      let sec = (rem%60)
      return hr+" hrs "+min+" min "+sec+" secs ago"
    }else{
      let day = Math.floor(diff/(3600*24))
      let hrRem = (diff%(3600*24))
      let hr = Math.floor(hrRem/3600)
      let rem = (hrRem%3600)
      let min = Math.floor(rem/60)
      let sec = (rem%60)
      return day+" days "+hr+" hrs "+min+" min "+sec+" secs ago"
    }

    // return diff
  }


  const getStopDuration=()=>{
    let time = ()=>{
      if(geo.active_time){
        return geo.active_time
      }else{
        return geo.update_time
      }
    }

    let t = time()

    return t?stop_duration(t):"undefined"
  }

  return (
    <InfoWindow
      position={{ lat: geo.lat, lng: geo.lng }}
      onCloseClick={() => onMarkerClick(false)}
    >
      <Box display="flex" flexDirection="column" style={{ minWidth: 400, maxWidth: 800,padding: 10 }}>
        <Box display="flex" justifyContent="space-between">
          <Typography
            style={{ textTransform: "uppercase", fontWeight: "bold" ,fontSize: 12 }}
          >
            ID:
          </Typography>
          <Typography style={{fontSize: 12 }}>{device.id}</Typography>
        </Box>

      

        <Box display="flex" justifyContent="space-between">
          <Typography
            style={{ textTransform: "uppercase", fontWeight: "bold",fontSize: 12 }}
          >
            Engine/Registration Number:
          </Typography>
          <Typography  style={{fontSize: 12 }}>{device.registration_number}</Typography>
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Typography
            style={{ textTransform: "uppercase", fontWeight: "bold",fontSize: 12 }}
          >
            Code:
          </Typography>
          <Typography  style={{fontSize: 12 }}>{device.code}</Typography>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
       
        >
          <Typography
            style={{ textTransform: "uppercase", fontWeight: "bold",fontSize: 12 }}
          >
            Current Location:
          </Typography>
          <Typography  style={{fontSize: 12,align:"right" }}>{address}</Typography>
        </Box>

      

      

        <Box display="flex" justifyContent="space-between">
          <Typography
            style={{ textTransform: "uppercase", fontWeight: "bold",fontSize: 12 }}
          >
            Ignition:
          </Typography>
          <Typography  style={{fontSize: 12 }}>{geo.acc}</Typography>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
  
        >
          <Typography
            style={{ textTransform: "uppercase", fontWeight: "bold",fontSize: 12 }}
          >
            Speed:
          </Typography>
          <Typography  style={{fontSize: 12 }}>{geo.speed} KMPH</Typography>
        </Box>

        {geo.charging && <Box
          display="flex"
          justifyContent="space-between"
     
        >
          <Typography
            style={{ textTransform: "uppercase", fontWeight: "bold",fontSize: 12 }}
          >
            Charging:
          </Typography>
          <Typography  style={{fontSize: 12 }}>{geo.charging}</Typography>
        </Box>}

        {geo.fuel_line && <Box
          display="flex"
          justifyContent="space-between"
  
        >
          <Typography
            style={{ textTransform: "uppercase", fontWeight: "bold",fontSize: 12 }}
          >
            Relay State:
          </Typography>
          <Typography  style={{fontSize: 12 }}>{geo.fuel_line}</Typography>
        </Box>}

        {geo.pto_io_status && <Box
          display="flex"
          justifyContent="space-between"
       
        >
          <Typography
            style={{ textTransform: "uppercase", fontWeight: "bold",fontSize: 12 }}
          >
            PTO IO STATUS:
          </Typography>
          <Typography  style={{fontSize: 12 }}>{geo.pto_io_status==="1"?"ON":"OFF"}</Typography>
        </Box>}

        {geo.over_speed && <Box
          display="flex"
          justifyContent="space-between"
   
        >
          <Typography
            style={{ textTransform: "uppercase", fontWeight: "bold",fontSize: 12 }}
          >
            OVERSPEED:
          </Typography>
          <Typography  style={{fontSize: 12 }}>{geo.over_speed==="1"?"TRUE":"FALSE"}</Typography>
        </Box>}

        {geo.temperature && <Box
          display="flex"
          justifyContent="space-between"
        
        >
          <Typography
            style={{ textTransform: "uppercase", fontWeight: "bold",fontSize: 12 }}
          >
            TEMPERATURE:
          </Typography>
          <Typography  style={{fontSize: 12 }}>{geo.temperature}</Typography>
        </Box>}

        {geo.number_of_satellite && <Box
          display="flex"
          justifyContent="space-between"
     
        >
          <Typography
            style={{ textTransform: "uppercase", fontWeight: "bold",fontSize: 12 }}
          >
            NO OF SATELLITE:
          </Typography>
          <Typography  style={{fontSize: 12 }}>{geo.number_of_satellite} Nos</Typography>
        </Box>}

        {geo.voltage_level && <Box
          display="flex"
          justifyContent="space-between"
        
        >
          <Typography
            style={{ textTransform: "uppercase", fontWeight: "bold",fontSize: 12 }}
          >
            VOLTAGE LEVEL:
          </Typography>
          <Typography  style={{fontSize: 12 }}>{geo.voltage_level}</Typography>
        </Box>}

        

        <Box
          display="flex"
          justifyContent="space-between"
       
        >
          <Typography
            style={{ textTransform: "uppercase", fontWeight: "bold",fontSize: 12 }}
          >
            Last Update:
          </Typography>

          <Typography  style={{fontSize: 12 }}>
            {geo.update_time
              ? moment(geo.update_time).format("MMMM DD YYYY, h:mm:ss a")
              : "undefined"}
          </Typography>

         
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
       
        >
          <Typography
            style={{ textTransform: "uppercase", fontWeight: "bold",fontSize: 12 }}
          >
            Stop Duration:
          </Typography>

          <Typography  style={{fontSize: 12 }}>
            {getStopDuration()}
          </Typography>

         
        </Box>


      </Box>
    </InfoWindow>
  );
};


export default MyInfoWindow
