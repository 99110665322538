import React, { useState, useEffect } from 'react';
import { utils, writeFileXLSX } from 'xlsx-js-style';
import {
  Card,
  Slide,
  IconButton,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  InputAdornment,
  Tooltip,
  InputLabel,
  Select,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import { Search, Clear, GetApp, AddBox } from '@material-ui/icons';
import dateformat from 'dateformat';
import { withDevice } from '../web/device';

import { RETAIL_COLLECTION_URL } from '../../utils/url';
import { grey } from '@material-ui/core/colors';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { CollectionTable } from '../web/component/virtual_table';

import axios from 'axios';

let headers = [
  'DEVICE ID',
  'REGISTRATION_NO',
  'CUSTOMER EMAIL',
  'CUSTOMER NUMBER',
  'SERVICE CHARGE',
  'PAYMENT STATUS',
];

const paymentMethods = ['bKASH', 'Cash', 'Nagad', 'Rocket'];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const PaymentDialog = ({
  payment_dialog_visible,
  closePaymentDialog,
  handleAddPayment,
}) => {
  const [state, setState] = useState({
    payment: {
      device_id: '',
      registration_number: '',
      customer_email: '',
      customer_number: '',
      service_charge: 0,
      payment_method: paymentMethods[0],
      year: 2023,
      month: 0,
      payment_status: true,
    },
    err: { error: false, message: '', fieldId: 0 },
  });

  const years = [2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];

  const monthArr = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const handleChange = (e) => {
    const cDev = { ...state.payment };
    if (
      e.target.name === 'month' ||
      e.target.name === 'year' ||
      e.target.name === 'service_charge'
    ) {
      cDev[e.target.name] = Number(e.target.value);
    } else {
      cDev[e.target.name] = e.target.value;
    }

    setState((old) => ({ ...old, payment: cDev }));
  };

  const handleSubmit = (e) => {
    if (state.payment.device_id === '') {
      setState((old) => ({
        ...old,
        err: {
          error: true,
          message: 'This is mendatory Field',
          fieldId: 1,
        },
      }));
    } else if (state.payment.registration_number === '') {
      setState((old) => ({
        ...old,
        err: {
          error: true,
          message: 'This is mendatory Field',
          fieldId: 2,
        },
      }));
    } else if (state.payment.customer_email === '') {
      setState((old) => ({
        ...old,
        err: {
          error: true,
          message: 'This is mendatory Field',
          fieldId: 3,
        },
      }));
    } else if (state.payment.customer_number === '') {
      setState((old) => ({
        ...old,
        err: {
          error: true,
          message: 'This is mendatory Field',
          fieldId: 4,
        },
      }));
    } else if (state.payment.service_charge <= 0) {
      setState((old) => ({
        ...old,
        err: {
          error: true,
          message: 'This is mendatory Field',
          fieldId: 5,
        },
      }));
    } else {
      handleAddPayment(state.payment);
    }
  };

  return (
    <Dialog
      open={payment_dialog_visible}
      onClose={closePaymentDialog}
      aria-labelledby="form-dialog-title"
      TransitionComponent={Transition}
    >
      <DialogTitle
        id="form-dialog-title"
        // style={{ textTransform: 'uppercase' }}
      >
        Make Payment
      </DialogTitle>

      <DialogContent>
        <Typography>
          To paid the amount of retail collection fill-up the form and press
          submit. Otherwise cancel the Dialog...
        </Typography>

        <TextField
          autoFocus
          error={state.err.error && state.err.fieldId === 1}
          label={
            state.err.error && state.err.fieldId === 1
              ? state.err.message
              : 'Device ID'
          }
          name="device_id"
          variant="outlined"
          onChange={handleChange}
          value={state.payment.device_id}
          fullWidth
          style={{ marginTop: 10 }}
          required
        />

        <TextField
          error={state.err.error && state.err.fieldId === 2}
          label={
            state.err.error && state.err.fieldId === 2
              ? state.err.message
              : 'Registration Number'
          }
          name="registration_number"
          variant="outlined"
          onChange={handleChange}
          value={state.payment.registration_number}
          fullWidth
          style={{ marginTop: 10 }}
          required
        />

        <TextField
          error={state.err.error && state.err.fieldId === 3}
          label={
            state.err.error && state.err.fieldId === 3
              ? state.err.message
              : 'Customer Email'
          }
          name="customer_email"
          variant="outlined"
          onChange={handleChange}
          value={state.payment.customer_email}
          fullWidth
          style={{ marginTop: 10 }}
          required
        />

        <TextField
          error={state.err.error && state.err.fieldId === 4}
          label={
            state.err.error && state.err.fieldId === 4
              ? state.err.message
              : 'Customer Number'
          }
          name="customer_number"
          variant="outlined"
          onChange={handleChange}
          value={state.payment.customer_number}
          fullWidth
          style={{ marginTop: 10 }}
          required
        />

        <TextField
          error={state.err.error && state.err.fieldId === 5}
          label={
            state.err.error && state.err.fieldId === 5
              ? state.err.message
              : 'Service Charge'
          }
          name="service_charge"
          variant="outlined"
          onChange={handleChange}
          value={state.payment.service_charge}
          fullWidth
          style={{ marginTop: 10 }}
          required
        />

        <InputLabel htmlFor="payment_method" style={{ marginTop: 10 }}>
          Select Payment Method
        </InputLabel>

        <Select
          style={{ marginTop: 10 }}
          native
          value={state.payment.payment_method}
          onChange={handleChange}
          inputProps={{
            name: 'payment_method',
            id: 'payment_method',
          }}
          fullWidth
        >
          {paymentMethods.map((ref, index) => (
            <option key={index} value={ref}>
              {ref}
            </option>
          ))}
        </Select>

        <InputLabel htmlFor="year" style={{ marginTop: 10 }}>
          Select Year
        </InputLabel>
        <Select
          style={{ marginTop: 10 }}
          native
          value={state.payment.year}
          onChange={handleChange}
          inputProps={{
            name: 'year',
            id: 'year',
          }}
          fullWidth
        >
          {years.map((ref, index) => (
            <option key={index} value={ref}>
              {ref}
            </option>
          ))}
        </Select>

        <InputLabel htmlFor="month" style={{ marginTop: 10 }}>
          Select Month
        </InputLabel>
        <Select
          style={{ marginTop: 10 }}
          native
          value={state.payment.month}
          onChange={handleChange}
          inputProps={{
            name: 'month',
            id: 'month',
          }}
          fullWidth
        >
          {monthArr.map((ref, index) => (
            <option key={index} value={index}>
              {ref}
            </option>
          ))}
        </Select>
      </DialogContent>

      <DialogActions>
        <Button onClick={closePaymentDialog} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ConfirmDialog = ({
  dialog_visible,
  closeDialog,
  handleSubmit,
  paymentMethod,
  setPaymentMethod,
}) => {
  return (
    <Dialog
      open={dialog_visible}
      onClose={closeDialog}
      aria-labelledby="form-dialog-title"
      TransitionComponent={Transition}
    >
      <DialogTitle
        id="form-dialog-title"
        // style={{ textTransform: 'uppercase' }}
      >
        Update Retail Collection
      </DialogTitle>

      <DialogContent>
        <Typography>
          To paid the amount of retail collection press submit. Otherwise cancel
          the Dialog...
        </Typography>

        <FormControl>
          <FormLabel id="demo-controlled-radio-buttons-group">
            Select Payment Method
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={paymentMethod}
            onChange={setPaymentMethod}
          >
            {paymentMethods.map((x) => (
              <FormControlLabel
                key={x}
                value={x}
                control={<Radio />}
                label={x}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const RetailCollection = ({ deviceParam }) => {
  const [state, setState] = useState({
    data: [],
    payment_method: paymentMethods[0],
    date: new Date(),
    dialog_visible: false,
    payment_dialog_visible: false,
    seletedItem: null,
    search: '',
    tableData: [],
  });

  const { setTitle } = deviceParam;

  const setPaymentMethod = (e) => {
    // console.log(e.target.value);

    setState((old) => ({ ...old, payment_method: e.target.value }));
  };

  const closePaymentDialog = () => {
    setState((old) => ({ ...old, payment_dialog_visible: false }));
  };

  useEffect(() => {
    const fetchData = () => {
      let data = {
        year: state.date.getFullYear(),
        month: state.date.getMonth(),
      };

      axios
        .post(RETAIL_COLLECTION_URL, data, {
          headers: {
            Authorization: 'Bearer ####rangs.sultantrack.com!!!!$$###',
          },
        })
        .then((response) => {
          setState((old) => ({
            ...old,
            data: response.data,
          }));
        })
        .catch((err) => console.log(err));
    };
    fetchData();
  }, [state.date]);

  const filterFunc = (device, search) => {
    return (
      device.device_id.toLowerCase().includes(search.toLowerCase()) ||
      device.registration_number.toLowerCase().includes(search.toLowerCase()) ||
      device.customer_email.toLowerCase().includes(search.toLowerCase()) ||
      device.customer_number.toLowerCase().includes(search.toLowerCase())
    );
  };

  const getColumnWidth = () => {
    //id	registration_number	chasis_number	model	code	day_count	start_time	end_time	location_count	distance	running_time	congestion_time	idle_time

    let data = [...state.data];
    let arr = [
      {
        wch: data.reduce(
          (w, r) => Math.max(w, r.device_id.length),
          headers[0].length
        ),
      },
      {
        wch: data.reduce(
          (w, r) => Math.max(w, r.registration_number.trim().length),
          headers[1].length
        ),
      },
      {
        wch: data.reduce(
          (w, r) => Math.max(w, r.customer_email.trim().length),
          headers[2].length
        ),
      },
      {
        wch: data.reduce(
          (w, r) => Math.max(w, r.customer_number.trim().length),
          headers[3].length
        ),
      },
      {
        wch: data.reduce(
          (w, r) => Math.max(w, r.service_charge.toString().trim().length),
          headers[4].length
        ),
      },
      {
        wch: data.reduce(
          (w, r) => Math.max(w, r.payment_status.length),
          headers[5].length
        ),
      },
    ];

    return arr;
  };

  const downloadFile = () => {
    // const ws = utils.json_to_sheet(

    // );

    let l = [...state.data].map((x) => {
      let y = {};
      y.device_id = x.device_id;
      y.registration_number = x.registration_number;
      y.customer_email = x.customer_email;
      y.customer_number = x.customer_number;
      y.service_charge = x.service_charge;
      y.payment_status = x.payment_status ? 'Paid' : 'Due';
      return y;
    });

    const ws = utils.json_to_sheet(l);
    ws['!cols'] = getColumnWidth();
    utils.sheet_add_aoa(ws, [headers], { origin: 'A1' });
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, dateformat(state.date, 'mmmm-yyyy'));
    writeFileXLSX(wb, dateformat(state.date, 'mmmm-yyyy') + '.xlsx');
  };

  useEffect(() => {
    const filterDevices = () => {
      if (state.search === '') {
        setState((old) => ({ ...old, tableData: old.data }));
      } else {
        setState((old) => ({
          ...old,
          tableData: [...old.data].filter((x) => filterFunc(x, state.search)),
        }));
      }
    };

    filterDevices();
  }, [state.search, state.data]);

  useEffect(() => {
    setTitle('Retail Collections');
  }, []);

  const closeDialog = () => {
    setState((old) => ({ ...old, dialog_visible: false }));
  };

  const handleChange = (e) => {
    setState((old) => ({ ...old, search: e.target.value }));
  };

  const prevClick = () => {
    let d = new Date(state.date);
    d.setMonth(d.getMonth() - 1);
    setState((old) => ({ ...old, date: d }));
  };

  const nextClick = () => {
    let d = new Date(state.date);
    d.setMonth(d.getMonth() + 1);
    setState((old) => ({ ...old, date: d }));
  };

  const handleSubmit = () => {
    axios
      .put(
        RETAIL_COLLECTION_URL + state.seletedItem._id,
        {
          payment_status: !state.seletedItem.payment_status,
          payment_method: state.payment_method,
        },
        {
          headers: {
            Authorization: 'Bearer ####rangs.sultantrack.com!!!!$$###',
          },
        }
      )
      .then((response) => {
        let datas = [...state.data];
        datas[datas.indexOf(state.seletedItem)] = response.data;
        setState((old) => ({
          ...old,
          data: datas,
          dialog_visible: false,
          seletedItem: null,
        }));
      })
      .catch((err) => console.log(err));
  };

  const onCheckClick = (rowData) => {
    setState((old) => ({ ...old, seletedItem: rowData, dialog_visible: true }));
  };

  const handleAddPayment = (payment) => {
    axios
      .post(RETAIL_COLLECTION_URL + 'add_payment', payment)
      .then((response) => {
        setState((old) =>
          response.data.month === state.date.getMonth()
            ? {
                ...old,
                data: [...old.data, response.data],
                payment_dialog_visible: false,
              }
            : { ...old, payment_dialog_visible: false }
        );
      })
      .catch((err) => console.log(err));
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 10,
        width: '100%',
        backgroundColor: grey[400],
      }}
    >
      <div style={{ display: 'flex', flex: 1 }}>
        <Card style={{ width: '100%', height: '100%' }}>
          <div
            style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  padding: 10,
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="button">
                    Total Vehicles ({state.data.length})
                  </Typography>
                  <Typography variant="button">
                    Paid Vehicles (
                    {state.data.filter((x) => x.payment_status).length})
                  </Typography>
                  <Typography variant="button">
                    Due Vehicles (
                    {state.data.filter((x) => !x.payment_status).length})
                  </Typography>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                  }}
                >
                  <Typography variant="button">
                    Total Collections (
                    {state.data.reduce(
                      (acc, obj) => acc + obj.service_charge,
                      0
                    )}
                    )
                  </Typography>
                  <Typography variant="button">
                    Paid Vehicles (
                    {state.data
                      .filter((x) => x.payment_status)
                      .reduce((acc, obj) => acc + obj.service_charge, 0)}
                    )
                  </Typography>
                  <Typography variant="button">
                    Due Vehicles (
                    {state.data
                      .filter((x) => !x.payment_status)
                      .reduce((acc, obj) => acc + obj.service_charge, 0)}
                    )
                  </Typography>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <IconButton onClick={prevClick}>
                  <ChevronLeft color="secondary" fontSize="large" />
                </IconButton>
                <Typography style={{ marginLeft: 20, marginRight: 20 }}>
                  Collection on {dateformat(state.date, 'mmmm-yyyy')}
                </Typography>
                <IconButton onClick={nextClick}>
                  <ChevronRight color="secondary" fontSize="large" />
                </IconButton>
              </div>
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'flex-end',
                }}
              >
                <Tooltip title="Add Payment">
                  <IconButton
                    style={{ marginRight: 10 }}
                    onClick={() =>
                      setState((old) => ({
                        ...old,
                        payment_dialog_visible: true,
                      }))
                    }
                  >
                    <AddBox />
                  </IconButton>
                </Tooltip>
                <TextField
                  style={{ marginRight: 10 }}
                  placeholder="Search"
                  onChange={handleChange}
                  name="search"
                  value={state.search}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search
                          style={{
                            color: grey[700],
                            padding: 4,
                            cursor: 'pointer',
                          }}
                        />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            setState((old) => ({ ...old, search: '' }))
                          }
                        >
                          <Clear
                            style={{
                              color: grey[700],
                              padding: 4,
                              cursor: 'pointer',
                            }}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <Tooltip title="Download">
                  <IconButton
                    style={{ marginRight: 10 }}
                    onClick={downloadFile}
                  >
                    <GetApp />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                flex: 1,
              }}
            >
              <CollectionTable
                data={state.tableData}
                onCheckClick={onCheckClick}
              />
            </div>
          </div>
        </Card>
      </div>

      <ConfirmDialog
        dialog_visible={state.dialog_visible}
        closeDialog={closeDialog}
        handleSubmit={handleSubmit}
        paymentMethod={state.payment_method}
        setPaymentMethod={setPaymentMethod}
      />
      <PaymentDialog
        payment_dialog_visible={state.payment_dialog_visible}
        closePaymentDialog={closePaymentDialog}
        handleAddPayment={handleAddPayment}
      />
    </div>
  );
};

export default withDevice(RetailCollection);
