import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
  Tooltip,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { Column, Table, AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css';
import { withFirebase } from '../Firebase';
import { withDevice } from '../web/device';
import { grey, lightBlue } from '@material-ui/core/colors';
import { fetchAllDevices } from '../../actions';
import { connect } from 'react-redux';
import {
  Search,
  Clear,
  DeleteOutline,
  ReportOutlined,
  LocationOnOutlined,
  EditOutlined,
  SettingsOutlined,
  AttachFileOutlined,
  Visibility,
  VisibilityOff,
  AddBox,
  MoreVert,
} from '@material-ui/icons';
import { getVehicleType } from '../../utils/types';
import AdminTableState from './admin_table_state';
import posed, { PoseGroup } from 'react-pose';
import AddDevice from './add_device';
import UpdateDevice from './update_device';
import DeleteDevice from './delete_device';
import AssignDevice from './assign';
import UnAssignDevice from './unassign';
import { AdminTable } from '../web/component/virtual_table';

const MyPage = posed.div({
  enter: {
    opacity: 1,

    y: 0,
    x: 0,
    transition: {
      y: { type: 'spring', stiffness: 1000, damping: 15 },
      default: { duration: 300 },
      // duration: 500,
    },
  },
  exit: {
    y: -100,
    x: -100,
    delay: 100,
    opacity: 0,
    transition: { duration: 500 },
  },
});

const DeviceTable = ({
  list,
  deviceParam,
  setAddOpen,
  setDeleteOpen,
  setUpdateOpen,
  setAssignOpen,
  setSettingOpen,
  setUnAssignOpen,
  setStatusVisible,
  statusVisible,
  clickState,
}) => {
  const {
    setDevice,
    handleMapClick,
    reportClick,
    device,
    dailyReportClick,
    monthlyTemperatureLog,
    monthlyPTIOReport,
    dailyTravelReportClick,
    dailySpeedReportClick,
  } = deviceParam;

  const [state, setState] = useState({
    search: '',
    visible: false,
    devices: [],
    anchorEl: null,
  });

  const open = Boolean(state.anchorEl);

  const handleClose = () => {
    setState((old) => ({ ...old, anchorEl: null }));
  };

  const headerStyle = {
    color: grey[700],
  };

  const rowStyle = (index) => {
    if (index % 2 === 0) {
      return { backgroundColor: grey[100] };
    } else {
      return { backgroundColor: grey[300] };
    }
  };

  const handleMoreClick = (e, device) => {
    setDevice(device);
    setState((old) => ({ ...old, anchorEl: e.currentTarget }));
  };

  const handleChange = (e) => {
    let val = e.target.value;
    setState((old) => ({ ...old, search: val }));
  };
  const settingClick = (device) => {
    setDevice(device);
    setSettingOpen(true);
  };

  const filterFunc = (device, search) => {
    return (
      device.id.toLowerCase().includes(search.toLowerCase()) ||
      device.registration_number.toLowerCase().includes(search.toLowerCase()) ||
      device.device_sim_number.toLowerCase().includes(search.toLowerCase()) ||
      (device.uid &&
        device.uid.email &&
        device.uid.email.toLowerCase().includes(search.toLowerCase()))
    );
  };

  useEffect(() => {
    const filterDevices = () => {
      if (state.search === '') {
        setState((old) => ({ ...old, devices: list }));
      } else {
        setState((old) => ({
          ...old,
          devices: [...list].filter((x) => filterFunc(x, state.search)),
        }));
      }
    };

    filterDevices();
  }, [state.search, list]);

  useEffect(() => {
    if (clickState === 0) {
      setState((old) => ({
        ...old,
        devices: [...list].filter((dev) => dev.geo),
      }));
    } else if (clickState === 1) {
      setState((old) => ({
        ...old,
        devices: [...list].filter((dev) => !dev.geo),
      }));
    } else if (clickState === 2) {
      setState((old) => ({
        ...old,
        devices: [...list].filter((dev) => dev.geo && dev.geo.acc === 'ON'),
      }));
    } else if (clickState === 3) {
      setState((old) => ({
        ...old,
        devices: [...list].filter((dev) => dev.geo && dev.geo.acc === 'OFF'),
      }));
    } else if (clickState === 4) {
      setState((old) => ({
        ...old,
        devices: [...list].filter((dev) => dev.uid),
      }));
    } else if (clickState === 5) {
      setState((old) => ({
        ...old,
        devices: [...list].filter((dev) => !dev.uid),
      }));
    }
  }, [clickState]);

  return (
    <Card style={{ width: '100%', height: '100%' }}>
      <Box
        display="flex"
        flexDirection="column"
        style={{ width: '100%', height: '100%' }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{ padding: 10 }}
        >
          <Box display="flex" flexDirection="column">
            <Typography style={{ padding: 2 }}>
              Total Vehicles: ( {list.length} )
            </Typography>
            <Typography style={{ padding: 2 }}>
              Searched Vehicles: ( {state.devices.length} )
            </Typography>
          </Box>

          {state.visible && <CircularProgress color="secondary" />}

          <Box display="flex" alignItems="center">
            <Tooltip title="Create Device">
              <IconButton
                style={{ marginRight: 10 }}
                onClick={() => setAddOpen((old) => !old)}
              >
                <AddBox />
              </IconButton>
            </Tooltip>
            <Tooltip title={statusVisible ? 'Hide States' : 'Show States'}>
              <IconButton
                style={{ marginRight: 10 }}
                onClick={() => setStatusVisible((old) => !old)}
              >
                {statusVisible ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </Tooltip>

            <TextField
              placeholder="Search"
              onChange={handleChange}
              name="search"
              value={state.search}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search
                      style={{
                        color: grey[700],
                        padding: 4,
                        cursor: 'pointer',
                      }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setState((old) => ({ ...old, search: '' }))
                      }
                    >
                      <Clear
                        style={{
                          color: grey[700],
                          padding: 4,
                          cursor: 'pointer',
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>

        <Box display="flex" flexGrow="1">
          <div style={{ width: '100%', height: '100%' }}>
            <AutoSizer>
              {({ width, height }) => (
                <Table
                  width={width}
                  height={height}
                  headerHeight={50}
                  rowHeight={50}
                  rowCount={state.devices.length}
                  rowGetter={({ index }) => state.devices[index]}
                  rowStyle={({ index }) => rowStyle(index)}
                  headerStyle={headerStyle}
                >
                  <Column
                    width={60}
                    flexGrow={1}
                    label="ID"
                    dataKey="id"
                    cellRenderer={({ cellData }) => (
                      <Typography variant="caption">
                        {cellData ? cellData : 'undefined'}
                      </Typography>
                    )}
                  />

                  <Column
                    width={60}
                    flexGrow={1}
                    label="Reg No"
                    dataKey="registration_number"
                    cellRenderer={({ cellData }) => (
                      <Typography variant="caption">
                        {cellData ? cellData : 'undefined'}
                      </Typography>
                    )}
                  />

                  <Column
                    width={60}
                    flexGrow={1}
                    label="Model"
                    dataKey="vehicle_model"
                    cellRenderer={({ cellData }) => (
                      <Typography variant="caption">
                        {cellData ? cellData : 'undefined'}
                      </Typography>
                    )}
                  />

                  <Column
                    width={60}
                    flexGrow={1}
                    label="SIM"
                    dataKey="device_sim_number"
                    cellRenderer={({ cellData }) => (
                      <Typography variant="caption">
                        {cellData ? cellData : 'undefined'}
                      </Typography>
                    )}
                  />

                  <Column
                    width={60}
                    flexGrow={2}
                    label="USER MAIL"
                    dataKey="uid"
                    cellRenderer={({ cellData }) => (
                      <Typography variant="caption">
                        {cellData ? cellData.email : 'undefined'}
                      </Typography>
                    )}
                  />

                  <Column
                    width={60}
                    flexGrow={1}
                    label="Type"
                    dataKey="vehicle_type"
                    cellRenderer={({ cellData }) => (
                      <Typography variant="caption">
                        {cellData ? getVehicleType(cellData) : 'undefined'}
                      </Typography>
                    )}
                  />

                  <Column
                    width={60}
                    flexGrow={1}
                    label="Device Model"
                    dataKey="device_model"
                    cellRenderer={({ cellData }) => (
                      <Typography variant="caption">
                        {cellData ? cellData : 'undefined'}
                      </Typography>
                    )}
                  />

                  <Column
                    headerStyle={{ textAlign: 'right', marginRight: 20 }}
                    width={60}
                    flexGrow={6}
                    label="Action"
                    dataKey="action"
                    cellRenderer={({ rowData }) => (
                      <Box display="flex" justifyContent="flex-end">
                        <Tooltip title="Map">
                          <IconButton
                            style={{ marginLeft: 2, marginRight: 2 }}
                            onClick={() => handleMapClick(rowData)}
                          >
                            <LocationOnOutlined />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Edit Vehicle">
                          <IconButton
                            style={{ marginLeft: 2, marginRight: 2 }}
                            onClick={() => setUpdateOpen(true, rowData)}
                          >
                            <EditOutlined />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Settings">
                          <IconButton
                            style={{ marginLeft: 2, marginRight: 2 }}
                            onClick={() => settingClick(rowData)}
                          >
                            <SettingsOutlined />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Assign">
                          <IconButton
                            style={{ marginLeft: 2, marginRight: 2 }}
                            onClick={() => setAssignOpen(true, rowData)}
                          >
                            <AttachFileOutlined />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="UnAssign">
                          <IconButton
                            style={{ marginLeft: 2, marginRight: 2 }}
                            onClick={() => setUnAssignOpen(true, rowData)}
                          >
                            <Clear />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="More..">
                          <IconButton
                            onClick={(e) => handleMoreClick(e, rowData)}
                            style={{ marginLeft: 2, marginRight: 2 }}
                          >
                            <MoreVert />
                          </IconButton>
                        </Tooltip>

                        <Menu
                          id="basic-menu"
                          anchorEl={state.anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}
                        >
                          <MenuItem onClick={() => dailyReportClick(device)}>
                            Daily Report
                          </MenuItem>
                          <MenuItem onClick={() => reportClick(device)}>
                            Monthly Report
                          </MenuItem>
                          <MenuItem
                            onClick={() => dailyTravelReportClick(device)}
                          >
                            Daily Travel Report
                          </MenuItem>
                          <MenuItem
                            onClick={() => dailySpeedReportClick(device)}
                          >
                            Speed Report
                          </MenuItem>
                          <MenuItem onClick={() => setDeleteOpen(true, device)}>
                            Delete
                          </MenuItem>

                          {device && device.device_model === 'S5E' && (
                            <MenuItem
                              onClick={() => monthlyTemperatureLog(device)}
                            >
                              Monthly Temperature Log
                            </MenuItem>
                          )}

                          {device && device.device_model === 'S5E' && (
                            <MenuItem onClick={() => monthlyPTIOReport(device)}>
                              PTIO Report
                            </MenuItem>
                          )}
                        </Menu>
                      </Box>
                    )}
                  />
                </Table>
              )}
            </AutoSizer>
          </div>
        </Box>
      </Box>
    </Card>
  );
};

const AdminDevices = ({ firebase, deviceParam, fetchAllDevices, devices }) => {
  const { setTitle, setDevice } = deviceParam;

  const [state, setState] = useState({
    statusVisible: false,
    clickState: -1,
    addOpen: false,
    updateOpen: false,
    deleteOpen: false,
    assignOpen: false,
    unassignOpen: false,
  });

  const setStatusVisible = (val) => {
    setState((old) => ({ ...old, statusVisible: val }));
  };

  const setClickState = (val) => {
    setState((old) => ({ ...old, clickState: val }));
  };

  const setAddOpen = (val) => {
    setState((old) => ({ ...old, addOpen: val }));
  };

  const setUpdateOpen = (val, selectedDevice) => {
    setState((old) => ({ ...old, updateOpen: val }));
    setDevice(selectedDevice);
  };

  const setDeleteOpen = (val, device) => {
    setState((old) => ({ ...old, deleteOpen: val }));
    setDevice(device);
  };

  const setAssignOpen = (val, device) => {
    setState((old) => ({ ...old, assignOpen: val }));
    setDevice(device);
  };

  const setUnAssignOpen = (val, device) => {
    setState((old) => ({ ...old, unassignOpen: val }));
    setDevice(device);
  };

  useEffect(() => {
    setTitle('List of All Devices');
    if (devices.length === 0) {
      fetchAllDevices(firebase);
    }
  }, []);
  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{ padding: 10, width: '100%', backgroundColor: grey[700] }}
    >
      <PoseGroup>
        {state.statusVisible && (
          <MyPage key="hhhh">
            <AdminTableState
              userDevices={devices}
              setClickState={setClickState}
            />
          </MyPage>
        )}
      </PoseGroup>

      <Box display="flex" flexGrow="1">
        {/* <AdminTable devices={devices}/> */}
        <DeviceTable
          list={devices}
          deviceParam={deviceParam}
          setStatusVisible={setStatusVisible}
          statusVisible={state.statusVisible}
          clickState={state.clickState}
          setAddOpen={setAddOpen}
          setDeleteOpen={setDeleteOpen}
          setUpdateOpen={setUpdateOpen}
          setAssignOpen={setAssignOpen}
          setUnAssignOpen={setUnAssignOpen}
        />
      </Box>

      <AddDevice open={state.addOpen} setOpen={setAddOpen} />
      <UpdateDevice open={state.updateOpen} setOpen={setUpdateOpen} />
      <DeleteDevice open={state.deleteOpen} setOpen={setDeleteOpen} />
      <AssignDevice open={state.assignOpen} setOpen={setAssignOpen} />
      <UnAssignDevice open={state.unassignOpen} setOpen={setUnAssignOpen} />
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllDevices: (firebase) => dispatch(fetchAllDevices(firebase)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFirebase(withDevice(AdminDevices)));
