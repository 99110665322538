import React, { useEffect, useState, useRef } from 'react';

import {
  ExpandLess,
  ExpandMore,
  SettingsBackupRestore,
} from '@material-ui/icons';
import {
  getActiveCount,
  getInactiveCount,
  getRunningCount,
  getStoppedCount,
} from '../../../utils/web_utils';
import {
  lastUpdate,
  stop_duration,
  getAddress,
  getDistance,
} from '../../../utils/utils';

import {
  Card,
  Divider,
  IconButton,
  Slide,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const DeviceInfo = ({ selectedDevice, status }) => {
  const [address, setAddress] = useState('');
  const locationRef = useRef(null);

  useEffect(() => {
    if (status && status.lat && status.lng) {
      // console.log(locationRef.current);
      if (
        !locationRef.current ||
        Object.keys(locationRef.current).length === 0 ||
        getDistance(
          { lat: locationRef.current.lat, lng: locationRef.current.lng },
          { lat: status.lat, lng: status.lng }
        ) >= 100
      ) {
        getAddress(status.lat, status.lng)
          .then((add) => setAddress(add))
          .catch((err) => console.log(err));
        locationRef.current = { ...status };
      }

      // ||
    }
  }, [status]);
  const Item = ({ title, value }) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: '1px solid grey',
        }}
      >
        <Typography variant="caption">{title}</Typography>
        <Typography variant="caption" style={{ textAlign: 'right' }}>
          {value}
        </Typography>
      </div>
    );
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 10,
        width: '100%',
      }}
    >
      <Item title="ID" value={selectedDevice.id} />
      <Item title="Reg. No" value={selectedDevice.registration_number} />
      <Item title="Igition" value={status && status.acc} />
      <Item title="Speed" value={status && status.speed} />
      <Item title="Charging" value={status && status.charging} />
      <Item title="Relay" value={status && status.fuel_line} />
      <Item title="Voltage level" value={status && status.voltage_level} />
      <Item
        title="Number of Satellite"
        value={status && status.number_of_satellite}
      />
      {selectedDevice.device_model === 'S5E' && (
        <div>
          <Item
            title="PTO io status"
            value={
              status && status.pto_io_status && status.pto_io_status === 1
                ? 'ON'
                : 'OFF'
            }
          />
          <Item
            title="Overspeed"
            value={
              status && status.over_speed && status.over_speed === 1
                ? 'True'
                : 'False'
            }
          />
          <Item title="Temperature" value={status && status.temperature} />
        </div>
      )}

      <Item
        title="Last Update"
        value={status && lastUpdate(status.update_time)}
      />

      <Item
        title="Stop Duration"
        value={
          status &&
          stop_duration(
            status.active_time ? status.active_time : status.update_time
          )
        }
      />
      <Item title="Address" value={status && address} />
    </div>
  );
};

const AllDeviceInfo = ({ sharedDevices, infoCardClick }) => {
  const MyCard = ({ title, value, titleMessage, index }) => {
    return (
      <Card
        square
        style={{ padding: 6, minWidth: 100, cursor: 'pointer' }}
        onClick={() => infoCardClick(index)}
      >
        <Tooltip title={titleMessage}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography>{title}</Typography>
            <Divider style={{ width: '90%' }} />
            <Typography>{value}</Typography>
          </div>
        </Tooltip>
      </Card>
    );
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 10,
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 10,
        }}
      >
        <MyCard
          title="Active"
          value={getActiveCount(sharedDevices)}
          titleMessage="Click to Select All Active Vehicles"
          index={0}
        />
        <MyCard
          title="In-Active"
          value={getInactiveCount(sharedDevices)}
          titleMessage="Click to Select All Inactive Vehicles"
          index={1}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 10,
        }}
      >
        <MyCard
          title="Running"
          value={getRunningCount(sharedDevices)}
          titleMessage="Click to Select All Running Vehicles"
          index={2}
        />
        <MyCard
          title="Stopped"
          value={getStoppedCount(sharedDevices)}
          titleMessage="Click to Select All Stopped Vehicles"
          index={3}
        />
      </div>
    </div>
  );
};

const SharedVehicleInfo = ({
  selectedDevice,
  status,
  sharedDevices,
  infoCardClick,
  handleShowAllVehiclesClick,
}) => {
  const containerRef = useRef(null);

  const [state, setState] = useState({
    open: true,
  });
  return (
    <div
      style={{
        position: 'absolute',
        bottom: 0,
        right: 0,
        width: '20%',
        height: '50%',
        zIndex: 500,
      }}
      ref={containerRef}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column-reverse',
        }}
      >
        {state.open && (
          <Slide
            direction="up"
            in={state.open}
            container={containerRef.current}
            mountOnEnter
            unmountOnExit
          >
            <div
              style={{
                display: 'flex',
                flex: 1,
                backgroundColor: grey[300],
                overflow: 'auto',
              }}
            >
              {selectedDevice ? (
                <DeviceInfo selectedDevice={selectedDevice} status={status} />
              ) : (
                <AllDeviceInfo
                  sharedDevices={sharedDevices}
                  infoCardClick={infoCardClick}
                />
              )}
            </div>
          </Slide>
        )}

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: 'white',
            alignItems: 'center',
            padding: 10,
          }}
        >
          <Typography variant="button">
            {selectedDevice
              ? 'Selected Vehicle Info'
              : 'Shared Vehicles Overview'}
          </Typography>
          <div style={{ display: 'flex' }}>
            {selectedDevice && (
              <Tooltip title="Click to show all Vehicles">
                <IconButton onClick={handleShowAllVehiclesClick}>
                  <SettingsBackupRestore />
                </IconButton>
              </Tooltip>
            )}

            <IconButton
              onClick={() => setState((old) => ({ ...old, open: !old.open }))}
            >
              {state.open ? <ExpandMore /> : <ExpandLess />}
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SharedVehicleInfo;
