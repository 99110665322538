import {
  FETCH_USERS,
  UPDATE_USER,
  SEARCH_USER,
  FETCH_DEVICES,
  ADD_DEVICE,
  UPDATE_DEVICE,
  DELETE_DEVICE,
  ASSIGN_DEVICE,
  UNASSIGN_DEVICE,
  GET_USER,
  ADD_USER,
  USER_DEVICES,
  FETCH_SHRED_USERS,
  DELETE_SHRED_DEVICE,
  ADD_SHRED_DEVICE,
  FETCH_SHRED_DEVICES,
  DELETE_USER_SHRED_DEVICE,
  AUTH_USER,
  DELETE_USER,
} from "../utils/types";
import axios from "axios";


import {
  USERS_URL,
  USERS_GET_BY_ID,
  DEVICES_URL,
  SHARED_USERS,
  SHARED_DEVICE,
  USER_SHARED_DEVICE,
} from "../utils/url";

const fetchUsersAction = (data) => {
  return { type: FETCH_USERS, payload: data };
};

const deleteUserAction = (data) => {
  return { type: DELETE_USER, payload: data };
};

const authUserAction = (data) => {
  return { type: AUTH_USER, payload: data };
};

const fetchSharedUsersAction = (data) => {
  return { type: FETCH_SHRED_USERS, payload: data };
};

const addSharedDeviceAction = (data) => {
  return { type: ADD_SHRED_DEVICE, payload: data };
};

const deleteSharedDeviceAction = (data) => {
  return { type: DELETE_SHRED_DEVICE, payload: data };
};

const deleteUserSharedDeviceAction = (data) => {
  return { type: DELETE_USER_SHRED_DEVICE, payload: data };
};

const userSharedDevicesAction = (data) => {
  return { type: FETCH_SHRED_DEVICES, payload: data };
};

const searchUsersAction = (data) => {
  return { type: SEARCH_USER, payload: data };
};

const fetchDevicesAction = (data) => {
  return { type: FETCH_DEVICES, payload: data };
};

const postUserAction = (data) => {
  return { type: ADD_USER, payload: data };
};

const updateUserAction = (newData, oldData) => {
  return { type: UPDATE_USER, payload: { newData: newData, oldData: oldData } };
};

const addDeviceAction = (data) => {
  return { type: ADD_DEVICE, payload: data };
};

const getUserAction = (user) => {
  return { type: GET_USER, payload: user };
};

const updateDeviceAction = (newData, oldData) => {
  return {
    type: UPDATE_DEVICE,
    payload: { newData: newData, oldData: oldData },
  };
};

const deleteDeviceAction = (oldData) => {
  return { type: DELETE_DEVICE, payload: oldData };
};

const assignDeviceAction = (newData, oldData) => {
  return {
    type: ASSIGN_DEVICE,
    payload: { newData: newData, oldData: oldData },
  };
};

const unAssignDeviceAction = (newData, oldData) => {
  return {
    type: UNASSIGN_DEVICE,
    payload: { newData: newData, oldData: oldData },
  };
};

const userDeviceListAction = (devices) => {
  return {
    type: USER_DEVICES,
    payload: devices,
  };
};

// Action of Axios Starts Here

export const registerUsers = (user) => {
  return (dispatch) => {
    return axios
      .post(USERS_URL, user)
      .then((response) => {
        dispatch(postUserAction(response.data));
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const fetchAllUsers = (firebase) => {
  return (dispatch) => {
    firebase.idToken().then((idToken) => {
      return axios
        .get(USERS_URL, { headers: { Authorization: "Bearer " + idToken } })
        .then((response) => {
          dispatch(fetchUsersAction(response.data));
        })
        .catch((err) => {
          throw err;
        });
    });
  };
};

export const fetchSharedUsers = (device_id) => {
  return (dispatch) => {
    return axios
      .get(SHARED_USERS + device_id)
      .then((response) => {
        dispatch(fetchSharedUsersAction(response.data));
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const getUser = (email) => {
  return (dispatch) => {
    return axios
      .get(USERS_URL + email)
      .then((response) => {
        dispatch(getUserAction(response.data));
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const updateUser = (newData, oldData, firebase, resolve) => {
  return (dispatch) => {
    let id = oldData._id;

    firebase.idToken().then((idToken) => {
      return axios
        .put(USERS_URL + id, newData, {
          headers: { Authorization: "Bearer " + idToken },
        })
        .then((response) => {
          dispatch(updateUserAction(response.data, oldData));
          resolve();
        })
        .catch((err) => {
          resolve();
          throw err;
        });
    });
  };
};

export const deleteUser = (user, firebase, resolve) => {
  return (dispatch) => {
    firebase.idToken().then((idToken) => {
      axios
        .delete(USERS_URL + user._id, {
          headers: { Authorization: "Bearer " + idToken },
        })
        .then((response) => {
          dispatch(deleteUserAction(user));
          resolve();
        })
        .catch((err) => {
          resolve();
          throw err;
        });
    });
  };
};

export const addDevice = (newData, firebase, resolve) => {
  return (dispatch) => {
    firebase.idToken().then((idToken) => {
      return axios
        .post(DEVICES_URL, newData, {
          headers: { Authorization: "Bearer " + idToken },
        })
        .then((response) => {
          dispatch(addDeviceAction(response.data));
          resolve();
        })
        .catch((err) => {
          resolve();
          throw err;
        });
    });
  };
};

export const fetchAllDevices = (firebase) => {
  return (dispatch) => {
    firebase.idToken().then((idToken) => {
      return axios
        .get(DEVICES_URL, { headers: { Authorization: "Bearer " + idToken } })
        .then((response) => {
          dispatch(fetchDevicesAction(response.data));
        })
        .catch((err) => {
          throw err;
        });
    });
  };
};

export const updateDevice = (newData, oldData, firebase, resolve) => {
  return (dispatch) => {
    let id = newData.id;

    firebase.idToken().then((idToken) => {
      return axios
        .put(DEVICES_URL + id, newData, {
          headers: { Authorization: "Bearer " + idToken },
        })
        .then((response) => {
          dispatch(updateDeviceAction(newData, oldData));
          resolve();
        })
        .catch((err) => {
          resolve();
          throw err;
        });
    });
  };
};

export const deleteDevice = (oldData, firebase, resolve) => {
  return (dispatch) => {
    firebase.idToken().then((idToken) => {
      axios
        .delete(DEVICES_URL + oldData.id, {
          headers: { Authorization: "Bearer " + idToken },
        })
        .then((response) => {
          dispatch(deleteDeviceAction(oldData));
          resolve();
        })
        .catch((err) => {
          resolve();
          throw err;
        });
    });
  };
};

export const assignDevice = (data, device, firebase, resolve) => {
  return (dispatch) => {
    firebase.idToken().then((idToken) => {
      return axios
        .post(DEVICES_URL + "assign", data, {
          headers: { Authorization: "Bearer " + idToken },
        })
        .then((response) => {
          dispatch(assignDeviceAction(response.data, device));
          resolve(true);
        })
        .catch((err) => {
          resolve(false);
          throw err;
        });
    });
  };
};

export const unAssignDevice = (device, firebase, resolve) => {
  return (dispatch) => {
    const data = { id: device.id };
    firebase.idToken().then((idToken) => {
      axios
        .post(DEVICES_URL + "unassign", data, {
          headers: { Authorization: "Bearer " + idToken },
        })
        .then((response) => {
          let newDevice = response.data;
          resolve();
          dispatch(unAssignDeviceAction(newDevice, device));
        })
        .catch((err) => {
          resolve();
          throw err;
        });
    });
  };
};

export const getUserDevices = (userId) => {
  return (dispatch) => {
    axios
      .get(USERS_URL + userId + "/devices")
      .then((response) => {
        let devices = response.data;
        dispatch(userDeviceListAction(devices));
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const getSearchUsers = (query) => {
  return (dispatch) => {
    dispatch(searchUsersAction(query));
  };
};

export const deleteSharedDevice = (oldData) => {
  return (dispatch) => {
    axios
      .delete(SHARED_DEVICE + oldData._id)
      .then((response) => {
        dispatch(deleteSharedDeviceAction(oldData));
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const deleteUserSharedDevice = (sharedDevice, resolve, firebase) => {
  return (dispatch) => {
    firebase.idToken().then((idToken) => {
      axios
        .delete(SHARED_DEVICE + sharedDevice._id, {
          headers: { Authorization: "Bearer " + idToken },
        })
        .then((response) => {
          dispatch(deleteUserSharedDeviceAction(sharedDevice));
          resolve();
        })
        .catch((err) => {
          resolve();
          throw err;
        });
    });
  };
};

export const userSharedDevices = (uid, firebase) => {
  return (dispatch) => {
    firebase.idToken().then((idToken) => {
      axios
        .get(USER_SHARED_DEVICE + uid+"/newroute", {
          headers: { Authorization: "Bearer " + idToken },
        })
        .then((response) => {
          dispatch(userSharedDevicesAction(response.data));
        })
        .catch((err) => {
          throw err;
        });
    });
  };
};

export const getAuthUser = (user, firebase) => {
  return (dispatch) => {
    if (user) {
      const { uid, email, emailVerified } = user;
      const bbb = {
        uid: uid,
        emailVerified: emailVerified,
        email: email,
        authToken: user.refreshToken,
      };

      firebase.idToken().then((idToken) => {
        axios
          .get(USERS_URL + user.email, {
            headers: { Authorization: "Bearer " + idToken },
          })
          .then((response) => {
            let dbUser = response.data;
            bbb["name"] = dbUser.name;
            bbb["is_admin"] = dbUser.is_admin;
            bbb["contact"] = dbUser.contact;
            bbb["image"] = dbUser.image;
            bbb["webuid"] = dbUser._id;
            bbb["organization_name"] = dbUser.organization_name;
            bbb["address"] = dbUser.address;
            bbb["token"] = dbUser.token;

            localStorage.setItem("authUser", JSON.stringify(bbb));
            dispatch(authUserAction(bbb));
          })
          .catch((err) => {
            throw err;
          });
      });
    } else {
      dispatch(authUserAction(null));
    }
  };
};

export const updateAuthUser = (authUser, formData, firebase, setFile,setSnacState) => {
  // console.log(callback, "Tester");

  return (dispatch) => {
    firebase.idToken().then((idToken) => {


      axios
        .put(USERS_URL + authUser.webuid, formData,{
          headers: { Authorization: "Bearer " + idToken },
        })
        .then((response) => {
          setFile(null);
          localStorage.setItem("authUser", JSON.stringify(response.data));
          setSnacState({
            open: true,
            message: "User Updated",
            severity: "success",
          });

          dispatch(authUserAction(response.data));
        })
        .catch((err) => {
          setSnacState({
            open: true,
            message: "Failed to update Profile",
            severity: "error",
          });
          console.log(err);
        });
    });
  };
};

export const addSharedDevice = (firebase,sharedDevice, resolve) => {
  return (dispatch) => {
    firebase.idToken().then((idToken) => {

      axios
      .post(SHARED_DEVICE, sharedDevice,{
        headers: { Authorization: "Bearer " + idToken },
      })
      .then((response) => {
        return response.data;
      })
      .then((sharedDevice) => {
        axios
          .get(USERS_GET_BY_ID + sharedDevice.user,{
            headers: { Authorization: "Bearer " + idToken },
          })
          .then((response) => {
            sharedDevice.user = response.data;
            console.log("OK",response.data)
            dispatch(addSharedDeviceAction(sharedDevice));
            resolve(true);
          })
          .catch((err) => {
            resolve(false);
            throw err;
          });
      })
      .catch((err) => {
        resolve(false);
        console.log(err);
      });

    })
    
  };
};
